import { SET_PATHNAME_FAILED, SET_PATHNAME_SUCCESS } from "../actions/types";

const initialState = {
    pathname: null
}

const setpathname = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_PATHNAME_SUCCESS:
            return {
                ...state,
                pathname: payload,
            }

        case SET_PATHNAME_FAILED:
            return state;
        default: return state;
    }
}
export default setpathname;