import { REGISTER_FAILED, REGISTER_REQUEST, REGISTER_SUCCESS } from "../actions/types";


const initialState = {
    isAuthenticated: null,
    type: "",
    loading: false,
    error: false,
    response: null
}

const registration = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case REGISTER_REQUEST:
            return {
                ...state,
                loading: payload
            }

        case REGISTER_SUCCESS:
            return {
                ...state,
                response: payload,
            }
        case REGISTER_FAILED:
            return state;
        default: return state;
    }
}
export default registration;