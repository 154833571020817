import React from 'react'
import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { NavHashLink } from 'react-router-hash-link';
import { decodedToken } from '../utils/decode';
import { add_to_cart } from '../redux/actions/add-to-cart';
import { get_cart_data } from '../redux/actions/get_cart_data';
import history from '../utils/utils';
import useLocalStorage from 'use-local-storage';
function Navbar({ pathname, get_cart_data, cart_items}) {
  const splittedURI = pathname ? pathname.split('/')[3] : '';
  const signed = decodedToken(sessionStorage.getItem('encode-utf-8'))

  const [theme, setTheme] = useLocalStorage('theme' ? 'dark' : 'light')
  
  const switchTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
  }

  return (
    <>
      <header id='navbar-header' className="header">
        <a href="#!" className="logo">
          <img width="60px" height="60px" src="/images/logo.png" alt="" />
        </a>
        
        {splittedURI === '#home' || splittedURI === null ? (
          <nav className="navbar">
            <a href="#hero">home</a>
            <a href="#about">about</a>
            <Link to="/cave-coffee-reservation">Bistro</Link>
            <a href="#products">store</a>
            <a href="#contact">contact</a>
            <a href="#blogs">blogs</a>
            <a href="#review">gallery</a>
          </nav>
        ) : (
            <nav className="navbar text-white-main">
              <NavHashLink smooth to="/#hero">home</NavHashLink>
              <NavHashLink smooth to="/#about">about</NavHashLink>
              <NavHashLink smooth to="/cave-coffee-reservation">Bistro</NavHashLink>
              <NavHashLink smooth to="/#products">store</NavHashLink>
              <NavHashLink smooth to="/#contact">contact</NavHashLink>
              <NavHashLink smooth to="/#blogs">blogs</NavHashLink>
              <NavHashLink smooth to="/#review">gallery</NavHashLink>
            </nav>
          ) ? signed ? ( 
              <nav className="navbar">
                <Link smooth to="/user-area" >home</Link>
                <Link smooth to="/user-area/products">store</Link>
              </nav>
            ) : (
                <nav className="navbar">
                  <NavHashLink smooth to="/#hero" >home</NavHashLink>
                  <NavHashLink smooth to="/#about">about</NavHashLink>
                  <NavHashLink smooth to="/cave-coffee-reservation">Bistro</NavHashLink>
                  <NavHashLink smooth to="/#products">store</NavHashLink>
                  <NavHashLink smooth to="/#contact">contact</NavHashLink>
                  <NavHashLink smooth to="/#blogs">blogs</NavHashLink>
                  <NavHashLink smooth to="/#review">gallery</NavHashLink>
                </nav>
            ) : (
                <nav className="navbar">
                  <NavHashLink smooth to="/#hero" >home</NavHashLink>
                  <NavHashLink smooth to="/#about">about</NavHashLink>
                  <NavHashLink smooth to="/cave-coffee-reservation">Bistro</NavHashLink>
                  <NavHashLink smooth to="/#products">store</NavHashLink>
                  <NavHashLink smooth to="/#contact">contact</NavHashLink>
                  <NavHashLink smooth to="/#blogs">blogs</NavHashLink>
                  <NavHashLink smooth to="/#review">gallery</NavHashLink>
                </nav>
            )}
        
        <div className="icons">
          {signed ? signed.token ? (
            <>
            <Tooltip placement='bottom' title={`${signed.user.name} ${signed.user.lname} account.`}>
                <Link to="/user-area/account"> <div className="bx bx-user"></div></Link>
            </Tooltip>
            <Tooltip placement='bottom' title={`Sign Out`}>
                <Link to="/logout">
                  <div className="bx bx-log-out-circle"></div>
              </Link>
            </Tooltip>
            </>
          )  :  (
              <Tooltip placement='bottom' title="Sign In">
                <NavHashLink smooth to="/login#">
                  <div className="bx bx-log-in-circle"></div>
                </NavHashLink>
              </Tooltip>
          ) : (
              <Tooltip placement='bottom' title="Sign In">
                <NavHashLink smooth to="/login#">
                  <div className="bx bx-log-in-circle"></div>
                </NavHashLink>
              </Tooltip>
          )}
          <div className="fas fa-search" id="search-btn"></div>
          <Link to="/cart">
            <Tooltip title='Shopping cart' placement='top'>
              <div className="fas fa-shopping-cart" id="cart-btn"></div>
              <span class="badge badge-danger p-2 rounded-50">{cart_items.length}</span>
            </Tooltip>
          </Link>    
          <div className="fas fa-bars" id="menu-btn"></div>
        </div>

        <div className="search-form">
          <input type="search" id="search-box" placeholder="search here..." />
          <label htmlFor="search-box" className="fas fa-search"></label>
        </div>
        

      </header>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    pathname: state.pathname.pathname,
    signed: state.signed.signed,
    cart_items: state.cart.cart_data
  }
}


export default connect(mapStateToProps, { add_to_cart})(Navbar)