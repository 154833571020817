import React, { useEffect, useState } from 'react'
import { Button, message, Form, Input, Divider, notification } from 'antd';
import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';
import { MailOutlined } from '@ant-design/icons';
import { setpathname } from '../redux/actions/setpathname';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import history from '../utils/utils';
import { encoded } from '../utils/encode';
import { Spinner } from 'react-bootstrap';
axios.defaults.withCredentials = true;
const SignUp = ({ setpathname }) => {
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        type: '_user_site',
        email: '',
        phone: '',
        password: '',
    })

    const [_gloading, setGLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const onChangeData = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const { firstname, lastname, phone, type, email, password } = formData;

    const handleFailure = (result) => {
        message.error(result, 5);
    }

    const handleLoginForm = async () => {
        axios.get('https://backend.cavecoffee.rw/sanctum/csrf-cookie').then(response => { })

        const config = {
            headers: {
                'Accept': 'application/json',
                'X-XSRF-TOKEN': '',
            }
        };
        const reqBody = {
            fname: firstname,
            lname: lastname,
            type: type,
            phone: phone,
            email: email,
            password: password
        };

        setLoading(true)
        await axios.post(`${process.env.REACT_APP_API_URL}/register`, reqBody, config)
            .then(res => {
                if (res.data.message) {
                    setLoading(false)
                    notification["error"]({
                        message: 'Something went wrong',
                        description: `${res.data.message}`,
                        duration: 6,
                        placement: 'topRight'
                    });
                } else {
                    sessionStorage.setItem("token", res.data.token)
                    sessionStorage.setItem("encode-utf-8", encoded(res.data))
                    setLoading(false)
                    notification["success"]({
                        message: 'Success!',
                        description: `Signup done successfully.`,
                        duration: 7,
                        placement: 'topRight'
                    });
                    history.push('/homepage');
                }
            })
            .catch(err => {
                setLoading(false)
                notification["error"]({
                    message: 'Something went wrong!',
                    description: `${err.message}`,
                    duration: 6,
                    placement: 'topRight'
                });
            })
    }

    const handleGoogleSignUpData = async (res) => {
        const _gdata = res.profileObj;
        await axios.get('https://backend.cavecoffee.rw/sanctum/csrf-cookie').then(response => { })
        const config = {
            headers: {
                'Accept': 'application/json',
                'X-XSRF-TOKEN': '',
            }
        };
        const reqBody = {
            fname: _gdata.givenName,
            lname: _gdata.familyName,
            type: '_user_google',
            phone: "",
            email: _gdata.email,
            password: ""
        };

        setGLoading(true)
        axios.post(`${process.env.REACT_APP_API_URL}/register`, reqBody, { withCredentials: true }, config)
            .then(res => {
                if (res.data.message) {
                    setGLoading(false)
                    notification["error"]({
                        message: 'Something went wrong',
                        description: `${res.data.message}`,
                        duration: 6,
                        placement: 'topRight'
                    });
                } else {
                    sessionStorage.setItem("token", res.data.token)
                    sessionStorage.setItem("encode-utf-8", encoded(res.data))
                    setGLoading(false)
                    notification["success"]({
                        message: 'Success!',
                        description: `Signup done successfully.`,
                        duration: 7,
                        placement: 'topRight'
                    });
                    history.push('/homepage');
                }
            })
            .catch(err => {
                setGLoading(false)
                notification["error"]({
                    message: 'Something went wrong!',
                    description: `${err.message}`,
                    duration: 6,
                    placement: 'topRight'
                });
            })

    }

    useEffect(() => {
        function start() {
            gapi.client.init({
                clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                scope: ""
            })
        };
        gapi.load('client:auth2', start);
        const currentPath = window.location.href;
        setpathname(currentPath)
    });

    return (
        <>
            <div className="container-fluid mt-8 mb-6">
                <div className="row">
                    <div className="col-md col-sm"></div>
                    <div className="col-md-6 col-sm-6">
                        <div className='card rounded-10 p-3 pt-5 card-2'>
                            <div className="card-body">
                                <Form
                                    name="basic"
                                    labelCol={{ span: 5 }}
                                    wrapperCol={{ span: 16 }}
                                    initialValues={{ remember: true }}
                                    onFinish={handleLoginForm}
                                    autoComplete="off"
                                >
                                    <Form.Item
                                        label="First Name"
                                        name="firstname"
                                        rules={[{ required: true, message: 'Please input your first name!' }]}
                                    >
                                        <Input name="firstname" placeholder='First Name' value={firstname} onChange={e => onChangeData(e)} />
                                    </Form.Item>

                                    <Form.Item
                                        label="Lastname Name"
                                        name="lastname"
                                        rules={[{ required: true, message: 'Please input your last name!' }]}
                                    >
                                        <Input name="lastname" placeholder='Last Name' value={lastname} onChange={e => onChangeData(e)} />
                                    </Form.Item>

                                    <Form.Item
                                        name="email"
                                        label="E-mail"
                                        rules={[
                                            {
                                                type: 'email',
                                                message: 'The input is not valid E-mail!',
                                            },
                                            {
                                                required: true,
                                                message: 'Please input your E-mail!',
                                            },
                                        ]}
                                    >
                                        <Input name="email" placeholder="Email" onChange={e => onChangeData(e)} value={email} prefix={<MailOutlined />} />
                                    </Form.Item>

                                    <Form.Item
                                        label="Phone number"
                                        name="phone"
                                        rules={[{ required: true, message: 'Please input your phone number!' }]}
                                    >
                                        <Input name="phone" placeholder='Phone Number' value={phone} onChange={e => onChangeData(e)} />
                                    </Form.Item>

                                    <Form.Item
                                        label="Password"
                                        name="password"
                                        rules={[{ required: true, message: 'Please input your password!' }]}
                                    >
                                        <Input.Password name="password" onChange={e => onChangeData(e)} value={password} placeholder='Password' />
                                    </Form.Item>
                                    <div className="row">
                                        <div className="col-sm-2 col-md-2"></div>
                                        <div className="col-sm col-md">
                                            <div className="ml-5">
                                                <Form.Item>
                                                    <Button type="primary" block htmlType="submit">
                                                        {loading ? (<Spinner
                                                            as="span"
                                                            animation="border"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />) : (<span>Sign Up </span>)}
                                                    </Button>
                                                </Form.Item>
                                            </div>
                                            <div className=" text-center mr-3">
                                                <span className='text-white'>Already have an account ? <Link to="/login">signin</Link> </span>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                                <Divider> <i>OR</i> </Divider>
                                <div className="container">
                                    <div className="text-center">
                                        <GoogleLogin
                                            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                            onSuccess={handleGoogleSignUpData}
                                            onFailure={handleFailure}
                                            cookiePolicy={'single_host_origin'}
                                        >
                                            {_gloading ? (<Spinner
                                                as="span"
                                                animation="border"
                                                role="status"
                                                aria-hidden="true"
                                            />) : (<span>Signup with Google</span>)}
                                        </GoogleLogin>
                                        {/* <div className="clearfix"></div>
                                        <GoogleLogout onLogoutSuccess={onLogoutSuccessful} clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                                            LogOut
                                        </GoogleLogout> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md col-sm"></div>
                </div>
            </div>
        </>
    )
}

export default connect(null, { setpathname })(SignUp)