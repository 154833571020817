import React from 'react'
import { connect } from 'react-redux';
import { NavHashLink } from 'react-router-hash-link'
import { decodedToken } from '../utils/decode';

const Footer = ({ pathname} ) => {
    const splittedURI = pathname ? pathname.split('/')[3] : '';
    const signed = decodedToken(sessionStorage.getItem('encode-utf-8'))
    const newYear = new Date().getFullYear()
    return (
        <>
            <section id='main-footer' className="footer">
                <div className="share">
                    <a className="text-white-main" target="_blank" href="#!" className="fab fa-facebook-f"></a>
                    <a className="text-white-main" target="_blank" href="https://twitter.com/cave_coffee_rw" className="fab fa-twitter"></a>
                    <a className="text-white-main" target="_blank" href="https://www.instagram.com/accounts/login/?next=/cave_coffee_rw/" className="fab fa-instagram"></a>
                    <p className="text-white-main contact-item" href="#">Phone number: +250 788 521 381</p>
                </div>
                <p className="text-white-main contact-item" href="#">Email :  <a className="link-white text-white-main" href="mailto:cavecoffeerwanda@gmail.com">cavecoffeerwanda@gmail.com</a> </p>
                {splittedURI === '#home' || splittedURI === null || splittedURI ==='cave-coffee-reservation' ? (
                    <nav className="links">
                        <NavHashLink className="text-white-main" smooth to="/#home" >Home</NavHashLink>
                        <NavHashLink className="text-white-main" smooth to="/#about">About</NavHashLink>
                        <NavHashLink className="text-white-main" smooth to="/#menu">Menu</NavHashLink>
                        <NavHashLink className="text-white-main" smooth to="/#products">Store</NavHashLink>
                        <NavHashLink className="text-white-main" smooth to="/#contact">Contact</NavHashLink>
                        <NavHashLink className="text-white-main" smooth to="/#blogs">Blogs</NavHashLink>
                        <NavHashLink className="text-white-main" smooth to="/#review">Gallery</NavHashLink>
                    </nav>
                ) : (<></>)}
                <div className="credit"> &copy; {newYear} Cave Coffee </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        pathname: state.pathname.pathname,
        signed: state.signed.signed,
        cart_items: state.cart.cart_data
    }
}

export default connect(mapStateToProps, null)(Footer);