import React, { useCallback, useEffect, useState } from 'react'
import AdminNavbar from './shared/AdminNavbar'
import AdminSidebar from './shared/AdminSidebar'
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
} from 'react-bs-datatable';
import { Button, Col, Form, Row, Spinner, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Modal, notification, Skeleton, Tooltip, Popconfirm, message } from 'antd';
import axios from 'axios';
function AdminBlog() {
    const token = sessionStorage.getItem("token")
    const [blogs, setBlogs] = useState([])
    const [loading, setLoading] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false)
    const [loadBlog, setLoadBlog] = useState(false)
    const [blog, setBlog] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [loadingDelete, setLoadingDelete] = useState(false)
    const [blogsTemp, setBlogsTemp] = useState([])
    const [formData, setFormData] = useState({
        title: "",
        paragraph1: "",
        paragraph2: "",
    })
    const { title, paragraph1, paragraph2 } = formData;

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    const getBlogs = async () => {
        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };

        setLoading(true)
        await axios.get(`${process.env.REACT_APP_API_URL}/getBlogs`, config)
            .then(res => {
                if (res.data.message) {
                    setLoading(false)
                    notification["error"]({
                        message: 'Something went wrong',
                        description: `${res.data.message}`,
                        duration: 6,
                        placement: 'topRight'
                    });
                } else {
                    setLoading(false)
                    setBlogsTemp(res.data)
                    // console.log("Blogs", formData);
                }
            })
            .catch(err => {
                setLoading(false)
                notification["error"]({
                    message: 'Something went wrong!',
                    description: `${err.message}`,
                    duration: 6,
                    placement: 'topRight'
                });
            })
    }

    const getBlogsCallback = useCallback(() => {
        if (blogsTemp.length === 0)
            getBlogs()
    }, [blogsTemp.length])

    useEffect(() => {
        document.getElementById('navbar-header').style.display = "none"
        document.getElementById('main-footer').style.display = "none";
        // document.getElementById('allbody').style.backgroundColor = '#eee';
        getBlogsCallback()

    }, [getBlogsCallback])



    /* update category actions */
    const showModal = (data) => {
        setIsModalVisible(true);
        const getBlog = async () => {
            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            };

            setLoadBlog(true)
            await axios.get(`${process.env.REACT_APP_API_URL}/getBlog/${data.id}`, config)
                .then(res => {
                    if (res.data.message) {
                        setLoadBlog(false)
                        notification["error"]({
                            message: 'Something went wrong',
                            description: `${res.data.message}`,
                            duration: 6,
                            placement: 'topRight'
                        });
                    } else {
                        setLoadBlog(false)
                        setBlog(res.data)
                        setFormData({ ...formData, title: res.data[0].Title, paragraph1: res.data[0].paragraph1, paragraph2: res.data[0].paragraph2 })
                         console.log('Get blog', blog)
                    }
                })
                .catch(err => {
                    setLoadBlog(false)
                    notification["error"]({
                        message: 'Something went wrong!',
                        description: `${err.message}`,
                        duration: 6,
                        placement: 'topRight'
                    });
                })
        }
        getBlog();

    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    

    const deleteBlog = (param) => {
        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        setLoadingDelete(true)
        axios.delete(`${process.env.REACT_APP_API_URL}/deleteBlog/${param.id}`, config)
            .then(res => {
                if (res.data) {
                    setLoadingDelete(false)
                    notification["error"]({
                        message: 'Something went wrong',
                        description: `${res.data.message}`,
                        duration: 6,
                        placement: 'topRight'
                    });
                } else {
                    setLoadingDelete(false)
                    getBlogs()
                    notification["success"]({
                        message: 'Success!',
                        description: `Blog has been deleted successfully.`,
                        duration: 7,
                        placement: 'topRight'
                    });
                }
            })
            .catch(err => {
                setLoadingDelete(false)
                notification["error"]({
                    message: 'Something went wrong!',
                    description: `${err.message}`,
                    duration: 6,
                    placement: 'topRight'
                });
            })
    }

    function cancel(e) {
        console.log(e);
        message.error('Delete has been cancelled.');
    }

    const confirmDelete = (data) => {
        deleteBlog(data)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        const reqBody = {
            Title: title,
            paragraph1: paragraph1,
            paragraph2: paragraph2
        };

        setUpdateLoading(true)
        await axios.put(`${process.env.REACT_APP_API_URL}/updateBlog/${blog[0].id}`, reqBody, config)
            .then(res => {
                if (res.data.message) {
                    setUpdateLoading(false)
                    notification["error"]({
                        message: 'Something went wrong',
                        description: `${res.data.message}`,
                        duration: 6,
                        placement: 'topRight'
                    });
                } else {
                    getBlogs()
                    setUpdateLoading(false)
                    notification["success"]({
                        message: 'Success!',
                        description: `Blog updated successfully.`,
                        duration: 7,
                        placement: 'topRight'
                    });
                    setIsModalVisible(false)
                }
            })
            .catch(err => {
                setUpdateLoading(false)
                notification["error"]({
                    message: 'Something went wrong!',
                    description: `${err.message}`,
                    duration: 6,
                    placement: 'topRight'
                });
            })
    }
    /* end of update actions  */

    const headers = [
        { title: 'Title', prop: 'Title', isFilterable: true },
        { title: 'paragraph1', prop: 'paragraph1', isFilterable: true },
        { title: 'paragraph2', prop: 'paragraph2', isFilterable: true },
        {
            prop: "edit",
            title: "Edit",
            cell: (row) => {
                return (
                    <Button onClick={() => showModal(row)} className="rounded-50" size="xs" variant="success">
                        <Tooltip title="Update blog" placement='bottom'>
                            <span><i className='bx bxs-edit-alt bx-sm text-white'></i></span>
                        </Tooltip>
                    </Button>
                );
            }
        },
        {
            prop: "delete",
            title: "Delete",
            cell: (row) => {
                return (
                    <Popconfirm
                        title="Are you sure to delete this blog item?"
                        onConfirm={() => confirmDelete(row)}
                        onCancel={cancel}
                        okText="Yes Delete!"
                        cancelText="Cancel"
                    >
                        {loadingDelete ? (
                            <Button className="rounded-50" size="xs" variant="danger">
                                <Tooltip title="Deletting" placement='bottom'>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        role="status"
                                        aria-hidden="true"
                                        size="sm"
                                    />
                                </Tooltip>
                            </Button>
                        ) : (
                            <Button className="rounded-50" size="xs" variant="danger">
                                <Tooltip title="Remove blog" placement='bottom'>
                                    <span><i className='bx bxs-trash-alt bx-sm text-white'></i></span>
                                </Tooltip>
                            </Button>
                        )}

                    </Popconfirm>
                );
            }
        },
    ];

    // Randomize data of the table columns.
    // Note that the fields are all using the `prop` field of the headers.

    const refreshButton = () => {
        setRefresh(true);
        getBlogs()
    }

    return (
        <>
            <main className='body-main'>
                <AdminSidebar />
                <section id="content">
                    <AdminNavbar />
                    <main>
                        <div className="head-title">
                            <div className="left">
                                <h1>Cave Coffee</h1>
                                <ul className="breadcrumb">
                                    <li>
                                        <a href="#!">Home</a>
                                    </li>
                                    <li><i className='bx bx-chevron-right' ></i></li>
                                    <li>
                                        <a className="active" href="#!">Blogs</a>
                                    </li>
                                </ul>
                            </div>
                            <a href="#!" className="btn-download d-none">
                                <i className='bx bxs-cloud-download' ></i>
                                <span className="text">Download PDF</span>
                            </a>
                        </div>

                        <div className="row">
                            <div className="col-md col-sm col-lg">
                                <div className="justify-content-end">
                                    <Link to="/admin/blogs/add-new" className="btn btn-primary float-right">
                                        <i className='bx bx-plus' ></i>
                                        <span className="text">Add Blog</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="table-data mb-6">
                            <div className="order">
                                <div className="row my-4">
                                    <div className="col-md col-sm col-lg">
                                        <div className="justify-content-end">
                                            <button onClick={refreshButton} className="btn btn-secondary float-right">
                                                {refresh && loading ? (<Spinner
                                                    as="span"
                                                    animation="border"
                                                    role="status"
                                                    aria-hidden="true"
                                                    size="sm"
                                                />) : (<span className="text">Refresh</span>)}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="head">
                                    <h3>Blogs</h3>
                                    <i className='bx bx-filter' ></i>
                                </div>
                                <DatatableWrapper body={blogsTemp}
                                    paginationOptionsProps={{
                                        initialState: {
                                            rowsPerPage: 5,
                                            options: [5, 10, 15, 20]
                                        }
                                    }}
                                    headers={headers}>
                                    <Row className="mb-4">
                                        <Col
                                            xs={12}
                                            lg={4}
                                            className="d-flex flex-col justify-content-end align-items-end"
                                        >
                                            <Filter />
                                        </Col>
                                        <Col
                                            xs={12}
                                            sm={6}
                                            lg={4}
                                            className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
                                        >
                                            <PaginationOptions />
                                        </Col>
                                        <Col
                                            xs={12}
                                            sm={6}
                                            lg={4}
                                            className="d-flex flex-col justify-content-end align-items-end"
                                        >
                                            <Pagination />
                                        </Col>
                                    </Row>
                                    {loading ? (<Skeleton paragraph={{ rows: 8 }} active loading={loading} />) : (
                                        <Table>
                                            <TableHeader />
                                            <TableBody />
                                        </Table>
                                    )}
                                </DatatableWrapper>
                            </div>
                        </div>
                    </main>
                </section>
            </main>
            <Modal title="Update Blog" visible={isModalVisible} footer={null} onOk={handleOk} onCancel={handleCancel}>
                {loadBlog ? (<Skeleton paragraph={{ rows: 8 }} avatar active loading={loadBlog} />) : (
                    <Form onSubmit={handleSubmit} className='my-4'>
                        <Form.Group className="mb-3">
                            <Form.Label>Blog title</Form.Label>
                            <Form.Control required name="title" onChange={e => onChange(e)} value={title} type="text" placeholder="Enter blog title" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Paragraph 1</Form.Label>
                            <Form.Control required name="paragraph1" onChange={e => onChange(e)} value={paragraph1} placeholder="Enter paragraph 1" as="textarea" rows={4} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Paragraph 2</Form.Label>
                            <Form.Control required name="paragraph2" onChange={e => onChange(e)} value={paragraph2} placeholder="Enter paragraph 2" as="textarea" rows={4} />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            {updateLoading ? (<Spinner
                                as="span"
                                animation="border"
                                role="status"
                                aria-hidden="true"
                            />) : (<span>Save changes </span>)}
                        </Button>
                    </Form>
                )}
            </Modal>
        </>
    )
}

export default AdminBlog