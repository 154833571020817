import { GET_CART_DATA_FAILED, GET_CART_DATA_SUCCESS } from "./types"
const json = localStorage.getItem("cart")
export const get_cart_data = () => dispatch => {
    if (json) {
        dispatch({
            type: GET_CART_DATA_SUCCESS,
            payload: JSON.parse(json)
        })
    } else {
        dispatch({
            type: GET_CART_DATA_FAILED,
            payload: []
        })
    }
}