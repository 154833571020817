import React, { useCallback, useEffect, useState } from 'react'
import AdminNavbar from './shared/AdminNavbar'
import AdminSidebar from './shared/AdminSidebar'
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
} from 'react-bs-datatable';
import { Button, Col, Form, Row, Spinner, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Modal, notification, Skeleton, Tooltip, Popconfirm, message } from 'antd';
import axios from 'axios';
function AdminReservations() {
    const token = sessionStorage.getItem("token")
    const [loading, setLoading] = useState(false)
    const [reservations, setReservations] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [loadingDelete, setLoadingDelete] = useState(false)
    const [formData, setFormData] = useState({
        Place: "",
        Content: "",
    })
    const { Place, Content } = formData;

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    const getReservations = async () => {
        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };

        setLoading(true)
        await axios.get(`${process.env.REACT_APP_API_URL}/getReservations`, config)
            .then(res => {
                if (res.data.message) {
                    setLoading(false)
                    notification["error"]({
                        message: 'Something went wrong',
                        description: `${res.data.message}`,
                        duration: 6,
                        placement: 'topRight'
                    });
                } else {
                    setLoading(false)
                    setReservations(res.data)
                    // console.log("Blogs", formData);
                }
            })
            .catch(err => {
                setLoading(false)
                notification["error"]({
                    message: 'Something went wrong!',
                    description: `${err.message}`,
                    duration: 6,
                    placement: 'topRight'
                });
            })
    }

    const getResCallback = useCallback(() => {
        if (reservations.length === 0)
            getReservations()
    }, [reservations.length])

    useEffect(() => {
        document.getElementById('navbar-header').style.display = "none"
        document.getElementById('main-footer').style.display = "none";
        // document.getElementById('allbody').style.backgroundColor = '#eee';
        getResCallback()

    }, [getResCallback])


    const deleteReservation = (param) => {
        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        setLoadingDelete(true)
        axios.delete(`${process.env.REACT_APP_API_URL}/deleteReservation/${param.id}`, config)
            .then(res => {
                if (res.data) {
                    setLoadingDelete(false)
                    notification["error"]({
                        message: 'Something went wrong',
                        description: `${res.data.message}`,
                        duration: 6,
                        placement: 'topRight'
                    });
                } else {
                    setLoadingDelete(false)
                    getReservations()
                    notification["success"]({
                        message: 'Success!',
                        description: `Reservation has been deleted successfully.`,
                        duration: 7,
                        placement: 'topRight'
                    });
                }
            })
            .catch(err => {
                setLoadingDelete(false)
                notification["error"]({
                    message: 'Something went wrong!',
                    description: `${err.message}`,
                    duration: 6,
                    placement: 'topRight'
                });
            })
    }

    function cancel(e) {
        console.log(e);
        message.error('Delete has been cancelled.');
    }

    const confirmDelete = (data) => {
        deleteReservation(data)
    }

    const onChangeSelect = (e) => {
        setFormData({ ...formData, Place: e.target.value })
        // console.log("Place", e.target.value)
    }
    /* end of update actions  */

    const headers = [
        { title: 'userName', prop: 'userName', isFilterable: true },
        { title: 'numberOfPeople', prop: 'numberOfPeople', isFilterable: true },
        { title: 'notes', prop: 'notes', isFilterable: true },
        { title: 'ArrivalDate', prop: 'ArrivalDate', isFilterable: true },
        { title: 'email', prop: 'email', isFilterable: true },
        { title: 'phone', prop: 'phone', isFilterable: true },
        // { title: 'Location', prop: 'Address2', isFilterable: true },
        {
            prop: "delete",
            title: "Delete",
            cell: (row) => {
                return (
                    <Popconfirm
                        title="Are you sure to delete this reservation ?"
                        onConfirm={() => confirmDelete(row)}
                        onCancel={cancel}
                        okText="Yes Delete!"
                        cancelText="Cancel"
                    >
                        {loadingDelete ? (
                            <Button className="rounded-50" size="xs" variant="danger">
                                <Tooltip title="Deletting" placement='bottom'>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        role="status"
                                        aria-hidden="true"
                                        size="sm"
                                    />
                                </Tooltip>
                            </Button>
                        ) : (
                            <Button className="rounded-50" size="xs" variant="danger">
                                <Tooltip title="Remove order" placement='bottom'>
                                    <span><i className='bx bxs-trash-alt bx-sm text-white'></i></span>
                                </Tooltip>
                            </Button>
                        )}

                    </Popconfirm>
                );
            }
        },
    ];

    // Randomize data of the table columns.
    // Note that the fields are all using the `prop` field of the headers.

    const refreshButton = () => {
        setRefresh(true);
        getReservations()
    }

    return (
        <>
            <main className='body-main'>
                <AdminSidebar />
                <section id="content">
                    <AdminNavbar />
                    <main>
                        <div className="head-title">
                            <div className="left">
                                <h1>Cave Coffee</h1>
                                <ul className="breadcrumb">
                                    <li>
                                        <a href="#!">Home</a>
                                    </li>
                                    <li><i className='bx bx-chevron-right' ></i></li>
                                    <li>
                                        <a className="active" href="#!">Reservations</a>
                                    </li>
                                </ul>
                            </div>
                            <a href="#!" className="btn-download d-none">
                                <i className='bx bxs-cloud-download' ></i>
                                <span className="text">Download PDF</span>
                            </a>
                        </div>

                        <div className="table-data mb-6">
                            <div className="order">
                                <div className="row my-4">
                                    <div className="col-md col-sm col-lg">
                                        <div className="justify-content-end">
                                            <button onClick={refreshButton} className="btn btn-secondary float-right">
                                                {refresh && loading ? (<Spinner
                                                    as="span"
                                                    animation="border"
                                                    role="status"
                                                    aria-hidden="true"
                                                    size="sm"
                                                />) : (<span className="text">Refresh</span>)}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="head">
                                    <h3>Reservations Orders</h3>
                                    <i className='bx bx-filter' ></i>
                                </div>
                                <DatatableWrapper body={reservations}
                                    paginationOptionsProps={{
                                        initialState: {
                                            rowsPerPage: 5,
                                            options: [5, 10, 15, 20]
                                        }
                                    }}
                                    headers={headers}>
                                    <Row className="mb-4">
                                        <Col
                                            xs={12}
                                            lg={4}
                                            className="d-flex flex-col justify-content-end align-items-end"
                                        >
                                            <Filter />
                                        </Col>
                                        <Col
                                            xs={12}
                                            sm={6}
                                            lg={4}
                                            className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
                                        >
                                            <PaginationOptions />
                                        </Col>
                                        <Col
                                            xs={12}
                                            sm={6}
                                            lg={4}
                                            className="d-flex flex-col justify-content-end align-items-end"
                                        >
                                            <Pagination />
                                        </Col>
                                    </Row>
                                    {loading ? (<Skeleton paragraph={{ rows: 8 }} active loading={loading} />) : (
                                        <Table>
                                            <TableHeader />
                                            <TableBody />
                                        </Table>
                                    )}
                                </DatatableWrapper>
                            </div>
                        </div>
                    </main>
                </section>
            </main>
        </>
    )
}

export default AdminReservations