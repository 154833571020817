
import registration from './signup'
import { combineReducers } from 'redux'
import setpathname from './pathname'
import setSigned from './signed'
import addtocart from './addtocart'
import getcart from './getcart'
import addorderReducer from './addorder'
export default combineReducers({
    registration,
    pathname: setpathname,
    signed: setSigned,
    cart: getcart,
    addtocart,
    add_an_order: addorderReducer
})