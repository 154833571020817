import { notification, Skeleton } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { setpathname } from '../redux/actions/setpathname'
import { decodedToken } from '../utils/decode'
import Gallery from './components/Gallery';
import Video from '../assets/videos/cave_coffee.mp4';
// styles import 
import '../assets/hero/css/style.css';
import '../assets/hero/css/normalize.css';
import 'object-fit-videos'
import { Link } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import { add_to_cart } from '../redux/actions/add-to-cart';
import { get_cart_data } from '../redux/actions/get_cart_data';
const Homepage = ({ setpathname }) => {
    var session = decodedToken(sessionStorage.getItem('encode-utf-8'))
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([])
    const [blogs, setBlogs] = useState([]);
    const [lodBlogs, setLoadBlogs] = useState([]);
    const [homeTexts, setHomeTexts] = useState([])
    const [text1, setText1] = useState([])
    const [text2, setText2] = useState([])
    const [text3, setText3] = useState([])
    const [text4, setText4] = useState([])
    const [text5, setText5] = useState([])
    const redirectToLogin = (logged) => {
        if (logged !== null) {
            if ((logged && logged.user.Type === "_user_site") || (logged.user.Type === "_user_google")) {
                return window.location.href = '/user-area';
            } else if (logged && logged.user.Type === "Admin") {
                return window.location.href = '/admin-dashboard';
            } else {
                return null;
            }
        }
    }
    useEffect(() => {
        const currentPath = window.location.href;
        setpathname(currentPath)
        redirectToLogin(session)
        const getProducts = async () => {
            const config = {
                headers: {
                    'Accept': 'application/json',
                }
            };
            setLoading(true)
            await axios.get(`${process.env.REACT_APP_API_URL}/getAllProducts`, config)
                .then(res => {
                    if (res.data.message) {
                        setLoading(false)
                        notification["error"]({
                            message: 'Something went wrong',
                            description: `${res.data.message}`,
                            duration: 6,
                            placement: 'topRight'
                        });
                    } else {
                        setLoading(false)
                        setProducts(res.data)
                        // console.log("Data products", res.data)
                    }
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        if (products.length === 0)
            getProducts()

        // blogs
        const getBlogs = async () => {
            const config = {
                headers: {
                    'Accept': 'application/json',
                }
            };
            setLoadBlogs(true)
            await axios.get(`${process.env.REACT_APP_API_URL}/getBlogs`, config)
                .then(res => {
                    if (res.data.message) {
                        setLoadBlogs(false)
                        notification["error"]({
                            message: 'Something went wrong',
                            description: `${res.data.message}`,
                            duration: 6,
                            placement: 'topRight'
                        });
                    } else {
                        setLoadBlogs(false)
                        setBlogs(res.data)
                        // console.log("Blogs", res.data)
                    }
                })
                .catch(err => {
                    setLoadBlogs(false)
                })
        }
        if (blogs.length === 0)
            getBlogs()

        // get home texts
        const getTexts1 = async () => {
            const config = {
                headers: {
                    Accept: "application/json",
                },
                params: {
                    Place: "video1",
                }
            };
            setLoading(true)
            await axios.get(`${process.env.REACT_APP_API_URL}/getHomeTextByPlace`, config)
                .then(res => {
                    if (res.data.message) {
                        setLoading(false)
                        notification["error"]({
                            message: 'Something went wrong',
                            description: `${res.data.message}`,
                            duration: 6,
                            placement: 'topRight'
                        });
                    } else {
                        setLoading(false)
                        setText1(res.data)
                        // console.log("Home texts 1", text1)
                    }
                })
                .catch(err => {
                    setLoading(false)
                })
        }

        //   get text 2
        // get home texts
        const getTexts2 = async () => {
            const config = {
                headers: {
                    Accept: "application/json",
                },
                params: {
                    Place: "video2",
                }

            };
            setLoading(true)
            await axios.get(`${process.env.REACT_APP_API_URL}/getHomeTextByPlace`, config)
                .then(res => {
                    if (res.data.message) {
                        setLoading(false)
                        notification["error"]({
                            message: 'Something went wrong',
                            description: `${res.data.message}`,
                            duration: 6,
                            placement: 'topRight'
                        });
                    } else {
                        setLoading(false)
                        setText2(res.data)
                        // console.log("Home texts 2", text2)
                    }
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        // texts 3
        // get home texts
        const getTexts3 = async () => {
            const config = {
                headers: {
                    Accept: "application/json",
                },
                params: {
                    Place: "about",
                }

            };
            setLoading(true)
            await axios.get(`${process.env.REACT_APP_API_URL}/getHomeTextByPlace`, config)
                .then(res => {
                    if (res.data.message) {
                        setLoading(false)
                        notification["error"]({
                            message: 'Something went wrong',
                            description: `${res.data.message}`,
                            duration: 6,
                            placement: 'topRight'
                        });
                    } else {
                        setLoading(false)
                        setText3(res.data)
                    }
                })
                .catch(err => {
                    setLoading(false)
                })
        }

        // get texts 4
        // get home texts
        const getTexts4 = async () => {
            const config = {
                headers: {
                    Accept: "application/json",
                },
                params: {
                    Place: "about2",
                }
            };
            setLoading(true)
            await axios.get(`${process.env.REACT_APP_API_URL}/getHomeTextByPlace`, config)
                .then(res => {
                    if (res.data.message) {
                        setLoading(false)
                        notification["error"]({
                            message: 'Something went wrong',
                            description: `${res.data.message}`,
                            duration: 6,
                            placement: 'topRight'
                        });
                    } else {
                        setLoading(false)
                        setText4(res.data)
                        // console.log("Home texts 4", text4)
                    }
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        // get texts 5
        // get home texts
        const getTexts5 = async () => {
            const config = {
                headers: {
                    Accept: "application/json",
                },
                params: {
                    Place: "about3",
                }

            };
            setLoading(true)
            await axios.get(`${process.env.REACT_APP_API_URL}/getHomeTextByPlace`, config)
                .then(res => {
                    if (res.data.message) {
                        setLoading(false)
                        notification["error"]({
                            message: 'Something went wrong',
                            description: `${res.data.message}`,
                            duration: 6,
                            placement: 'topRight'
                        });
                    } else {
                        setLoading(false)
                        setText5(res.data)
                        // console.log("Home texts 5", text5)
                    }
                })
                .catch(err => {
                    setLoading(false)
                })
        }

        // if (text1.length === 0) 
        getTexts1()
        // if (text2.length === 0) 
        getTexts2()
        // if (text3.length === 0) 
        getTexts3()
        // if (text4.length === 0) 
        getTexts4()
        // if (text5.length === 0) 
        getTexts5()

    }, [setpathname, session, products.length, blogs.length, text4.length])

    // handle add to cart
    return (
        <>
            <section id="hero" >
                <div class="texture"></div>
                <video
                    autoPlay loop muted
                    className='mb-5'
                >
                    <source src={Video} type="video/mp4" />
                    Your browser does not support video auto stream.
                </video>
                <div class="caption">
                    {/* <h1>Welcome to my website</h1>
                    <h2>This is a subtitle</h2> */}
                    <div className="content">
                        <h1 className="text-white-main">{text1 ? text1.length ? text1[0].Content : null : null}</h1>
                        <p className="text-12 text-white-main">{text2 ? text2.length ? text2[0].Content : null : null}</p>
                        <a href="#products" className="btn d-none">get yours now</a>
                    </div>
                </div>
            </section>

            <section className="about" id="about">
                <h1 className="heading text-white-main"> <span className="text-white-main">about</span> us </h1>
                {loading ? (<Skeleton paragraph={{ rows: 8 }} active loading={loading} />) : (
                    <div className="row">
                        <div className="image">
                            {text3 ? text3[0] ? (<img className='about-img' src={text3 ? text3[0].images.length ? text3[0].images[0].images : '/images/about-img.jpeg' : '/images/about-img.jpeg'} alt="" />) : <img className='about-img' src="/images/about-img.jpeg" alt="" /> : <img className='about-img' src="/images/about-img.jpeg" alt="" />}
                        </div>
                        <div className="content text-white-main">
                            <h3 className="text-white-main">About us</h3>
                            <p className="text-white-main">{text3 ? text3.length ? `${text3[0].Content.substring(0, 150)} ...` : null : null}</p>
                            <p className="d-none">{text5 ? text5.length ? text5[0].Content : null : null}</p>
                            <Link to="/about-us" className="btn text-main">Read more</Link>
                        </div>
                    </div>
                )}

            </section>

            <section className="products" id="products">
                <h1 className="heading text-white-main"> Our <span>store</span> </h1>
                <div className="box-container">
                    {
                        loading ? (<section className='my-5'>
                            <Skeleton paragraph={{ rows: 16 }} active loading={loading} />
                        </section>) :
                            products.map((prod, i) => (
                                <div key={i} className="box">
                                    <div className="icons">
                                        <a href="#!" className="fas fa-shopping-cart d-none"></a>
                                        <Link to={`/product/details/${prod.id}`} className="fas fa-eye"></Link>
                                    </div>
                                    <div className="image">
                                        <img src={prod.images.length > 0 ? prod.images[0].name : '/images/coffee-blog.png'} alt="" />
                                    </div>
                                    <div className="content">
                                        <h3>{prod.Title}</h3>
                                        <div className="stars">
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star-half-alt"></i>
                                        </div>
                                        <div className="price">RWF {prod.PriceAfterDiscount} <span>RWF {prod.PricebeforeDiscount} </span></div>
                                    </div>
                                </div>
                            ))
                    }
                </div>
            </section>
            <section className="gall" id="review">
                <h1 className="heading text-white-main"> <span>Gallery</span> </h1>
                <Gallery />
            </section>
            
            <section className="contact" id="contact">

                <h1 className="heading text-white-main"> <span>contact</span> us </h1>

                <div className="row">
                    <iframe title='map' className="map" src="https://www.google.com/maps/embed/v1/place?q=Cave+coffee&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" allowFullScreen="" loading="lazy"></iframe>

                    <form action="">
                        <h3 className="text-white-main">get in touch</h3>
                        <div className="inputBox">
                            <span className="fas fa-user"></span>
                            <input type="text" placeholder="name" />
                        </div>
                        <div className="inputBox">
                            <span className="fas fa-envelope"></span>
                            <input type="email" placeholder="email" />
                        </div>
                        <div className="inputBox">
                            <span className="fas fa-phone"></span>
                            <input type="number" placeholder="number" />
                        </div>
                        <input type="submit" value="contact now" className="btn" />
                    </form>

                </div>

            </section>

            <section className="blogs text-white-main" id="blogs">
                <h1 className="heading text-white-main"> our <span>blogs</span> </h1>
                <div className="box-container">
                    {
                        loading ? (<section className='my-8'>
                            <Skeleton paragraph={{ rows: 8 }} active loading={loading} />
                        </section>) :
                            blogs.map((blog, index) => (
                                <div key={index} className="box">
                                    <div className="image">
                                        <img src={blog.images.length > 0 ? blog.images[0].name : '/images/coffee-blog.png'} alt="" />
                                    </div>
                                    <div className="content">
                                        <a href="#!" className="title text-white-main">{blog.Title}</a>
                                        <p className="text-white-main">{blog.paragraph1}.</p>
                                        <NavHashLink to={`/blog/${blog.id}`} className="btn text-main">read more</NavHashLink>
                                    </div>
                                </div>
                            ))
                    }
                </div>

            </section>
        </>
    )
}

export default connect(null, { setpathname })(Homepage);
