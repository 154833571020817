import { notification, Skeleton } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { setpathname } from '../redux/actions/setpathname'
import { decodedToken } from '../utils/decode'
// styles import 
import '../assets/hero/css/style.css';
import '../assets/hero/css/normalize.css';
import 'object-fit-videos'
import { Link } from 'react-router-dom';
const AboutDetails = ({ setpathname }) => {
    var session = decodedToken(sessionStorage.getItem('encode-utf-8'))
    const [loading, setLoading] = useState(false);
    const [text3, setText3] = useState([])
    const [text4, setText4] = useState([])
    const [text5, setText5] = useState([])
    const redirectToLogin = (logged) => {
        if (logged !== null) {
            if ((logged && logged.user.Type === "_user_site") || (logged.user.Type === "_user_google")) {
                return window.location.href = '/user-area';
            } else if (logged && logged.user.Type === "Admin") {
                return window.location.href = '/admin-dashboard';
            } else {
                return null;
            }
        }
    }
    useEffect(() => {
        const currentPath = window.location.href;
        setpathname(currentPath)
        redirectToLogin(session) 
    // get home texts
       
        // texts 3
        // get home texts
        const getTexts3 = async () => {
            const config = {
                headers: {
                    Accept: "application/json",
                },
                params: {
                    Place: "about",
                }

            };
            setLoading(true)
            await axios.get(`${process.env.REACT_APP_API_URL}/getHomeTextByPlace`, config)
                .then(res => {
                    if (res.data.message) {
                        setLoading(false)
                        notification["error"]({
                            message: 'Something went wrong',
                            description: `${res.data.message}`,
                            duration: 6,
                            placement: 'topRight'
                        });
                    } else {
                        setLoading(false)
                        setText3(res.data)
                        // console.log("Home texts 3", text3)
                    }
                })
                .catch(err => {
                    setLoading(false)
                })
        } 

        // get home texts
        const getTexts4 = async () => {
            const config = {
                headers: {
                    Accept: "application/json",
                },
                params: {
                    Place: "about2",
                }
            };
            setLoading(true)
            await axios.get(`${process.env.REACT_APP_API_URL}/getHomeTextByPlace`, config)
                .then(res => {
                    if (res.data.message) {
                        setLoading(false)
                        notification["error"]({
                            message: 'Something went wrong',
                            description: `${res.data.message}`,
                            duration: 6,
                            placement: 'topRight'
                        });
                    } else {
                        setLoading(false)
                        setText4(res.data)
                    }
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        // get texts 5
        // get home texts
        const getTexts5 = async () => {
            const config = {
                headers: {
                    Accept: "application/json",
                },
                params: {
                    Place: "about3",
                }

            };
            setLoading(true)
            await axios.get(`${process.env.REACT_APP_API_URL}/getHomeTextByPlace`, config)
                .then(res => {
                    if (res.data.message) {
                        setLoading(false)
                        notification["error"]({
                            message: 'Something went wrong',
                            description: `${res.data.message}`,
                            duration: 6,
                            placement: 'topRight'
                        });
                    } else {
                        setLoading(false)
                        setText5(res.data)
                        // console.log("Home texts 5", text5)
                    }
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        // if (text3.length === 0) 
        getTexts3()
        // if (text4.length === 0) 
        getTexts4()
        // if (text5.length === 0) 
        getTexts5()
        // get texts 4
    }, [text3.length,session,setpathname])
    return (
        <>
            {loading ? (
                <section className="my-8">
                    <Skeleton paragraph={{ rows: 8 }} active loading={loading} />
                </section>
            ) : (
                    <section className="about text-white-main" id="about">
                        <h1 className="heading"> <span className="text-white-main">about</span> us </h1>
                    <div className="row">
                            <div className="image">
                                {text3 ? text3[0] ? (<img className='about-img' src={text3 ? text3[0].images.length ? text3[0].images[0].images : '/images/about-img.jpeg' : '/images/about-img.jpeg'} alt="" />) : <img className='about-img' src="/images/about-img.jpeg" alt="" /> : <img className='about-img' src="/images/about-img.jpeg" alt="" />}
                            </div>
                        <div className="content">
                            <h3 className="text-white-main">About us</h3>
                            <p className="text-white-main">{text3 ? text3.length ? text3[0].Content : null : null}</p>
                            <p className="d-none">{text5 ? text5.length ? text5[0].Content : null : null}</p>
                        </div>
                    </div>
                </section>
            )}
        </>
    )
}

export default connect(null, { setpathname })(AboutDetails);
