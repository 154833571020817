import { Button, Modal, message, notification } from 'antd'
import React, { useLayoutEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { decodedToken } from '../../../utils/decode';
import { Form, Spinner } from 'react-bootstrap';
import SideBar from './Sidebar'
import { add_an_order } from '../../../redux/actions/add_order';
import Logo from '../../../assets/img/log_payment.png';
import axios from 'axios'
const token = sessionStorage.getItem("token")
// const Flutterwave = require('flutterwave-node-v3');
const CheckOut = ({ cart_items, add_an_order, loading, order_is_added, orders }) => {
    const navigate = useNavigate();
    const [products, setProducts] = useState([])
    const [singleProduct, setSingleProduct] = useState([])
    const [calcPrice, setCalcPrice] = useState(0)
    const [adding, setAdding] = useState(false);
    const [geoData, setGeoData] = useState({
        long: '',
        lat: '',
    })
    const [formData, setFormData] = useState({
        "Address1": "",
        "Address2": "",
        "phone": "",
        "country": "",
        "city": "",
        "zip": "",
        "is_paid": "false",
        "Products": [],
        "payment": 0,
        "location": [{
            "longtude": "",
            "lattude": "",
            "ip_address": ""
        }]

    })
    const { Address1, Address2, phone, country, city, zip, is_paid } = formData;
    const [ipAddress, setIp] = useState("")
    const [geoError, setGeoError] = useState("")
    var session = decodedToken(sessionStorage.getItem('encode-utf-8'))

    useMemo(() => {
        const calculatePrice = () => {
            if (cart_items) {
                if (cart_items.length) {
                    const priced = cart_items.map(item => item.price).reduce((prev, next) => prev + next);
                    setCalcPrice(priced)
                }
            }
        }
        calculatePrice()
    }, [cart_items])

    useLayoutEffect(() => {
        function getIPFromThirdParty() {
            fetch("https://quick-proxy.herokuapp.com/https://api.ipify.org?format=json")
                .then(res => res.text())
                .then(data => {
                    setIp(data)
                    // console.log("Location data", data)
                })
        }
        // Geo location
        function getLocation() {
            if (navigator.geolocation) {
                navigator.geolocation.watchPosition(showPosition);
            } else {
                setGeoError("Geolocation is not supported by this browser.");
            }
        }
        function showPosition(position) {
            setGeoData({
                lat: position.coords.latitude,
                long: position.coords.longitude
            })
        }
        if (geoData.lat === '' || geoData.long === '')
            getLocation()
        // console.log("Geo Data", geoData);
        if (ipAddress === "")
            getIPFromThirdParty()
        else {
            // console.log("Ip address", ipAddress);
        }

        // console.log("Data order", orders)

    })

    let nf = new Intl.NumberFormat('en-US');

    const handleCheckout = (e) => {
        e.preventDefault();
        var product = []
        cart_items.forEach((item) => {
            product.push({ id: item.id, productQuantity: item.cartItems, price_total: item.price })
        })
        var ipData = ipAddress ? JSON.parse(ipAddress) : JSON.parse({ ip: "0.0.0.0" })
        setFormData({ ...formData, is_paid: "false" })
        var locationData = [{
            "longtude": geoData.long ? geoData.long : 0.000,
            "lattude": geoData.lat ? geoData.lat : 0.000,
            "ip_address": ipData.ip
        }]
        if (order_is_added) {
            message.warning('Order is already initiated please process the payment now.', 4)
        } else {
            add_an_order(Address1, Address2, phone, country, city, zip, is_paid, product, calcPrice, locationData)
        }
    }

    const onChangeSelectCountry = (e) => {
        // console.log('Selected', e)
        setFormData({ ...formData, country: e.target.value })
    }

    const onChangeSelectCity = (e) => {
        // console.log('Selected', e)
        setFormData({ ...formData, city: e.target.value })
    }

    const onChangeOrder = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = (e) => {
        setIsModalVisible(true);
        handleCheckout(e)
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    function makePayment() {
        window.FlutterwaveCheckout({
            public_key: `${process.env.REACT_APP_FLW_PUB}`,
            tx_ref: "Payment-checkout-48981487343MDI0NzMx",
            amount: 100,
            currency: "RWF",
            payment_options: "card, mobilemoneyrwanda, ussd",
            callback: function (payment) {
                // console.log("Sending Data payment", payment)
                verifyTransactionOnBackend(payment);
            },
            onclose: function (incomplete) {
                if (incomplete || window.verified === false) {
                    message.error('Payment failed and cancelled.')
                } else {
                    console.log("Window closed")
                }
            },
            meta: {
                consumer_id: 'ppppjyte%5555',
                consumer_mac: "92a3-912ba-1192a",
            },
            customer: {
                email: `${session.user.email}`,
                phone_number: `${phone}`,
                name: `${session.user.name} ${session.user.lname}`,
            },
            customizations: {
                title: "Cave Coffee Payment",
                description: "Cave Coffee payment",
                logo: `${Logo}`,
            },
        });
    }

    async function verifyTransactionOnBackend(param) {
        // Let's just pretend the request was successful
        window.verified = true;
        if (param.transaction_id && param.status === 'successful') {
            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            };
            const requestBody = {
                "order_id": orders ? orders.Order.id : 0,
                "payment_amount": param.amount,
                "taranaction_reference": param.flw_ref,
                "transaction_id": param.transaction_id,
            }
            axios.post(`${process.env.REACT_APP_API_URL}/payment`, requestBody, config)
                .then(res => {
                    if (res.data.message) {
                        setAdding(false)
                        notification["error"]({
                            message: 'Something went wrong',
                            description: `${res.data.message}`,
                            duration: 6,
                            placement: 'topRight'
                        });
                    } else {
                        setAdding(false)
                        notification["success"]({
                            message: 'Success!',
                            description: `Thank you for your payment on Cave Coffee you'll receive receipt email shorly.`,
                            duration: 7,
                            placement: 'topRight'
                        });
                        setTimeout(() => {
                            navigate("/orders")
                        }, 1000)
                    }
                })
                .catch(err => {
                    setAdding(false)
                    notification["error"]({
                        message: 'Something went wrong!',
                        description: `${err.message}`,
                        duration: 6,
                        placement: 'topRight'
                    });
                })
        }

    }

    return (
        <>
            <div className="row">
                <div className="col-sm-2 col-md-2">
                    <SideBar />
                </div>
                <div className="col-sm-10 col-md-10">
                    <section className="menu" id="menu">
                        <h1 className="heading"> <span>Checkout</span> </h1>
                        <div className="row">
                            <div className="card text-15 text-15 col-md-12 card-2 rounded-20 my-5">
                                <div className="card-body">
                                    <div className="py-5 text-center">
                                        <h4>Checkout</h4>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4 order-md-2 mb-4">
                                            <h4 className="d-flex justify-content-between align-items-center mb-3">
                                                <span className="text-white-main">Your cart</span>
                                                <span className="badge badge-danger badge-pill text-white-main">{cart_items.length}</span>
                                            </h4>
                                            <ul className="list-group card-2 mb-3">
                                                {cart_items.map((item, index) => (
                                                    <li key={index} className="list-group-item card-2 d-flex justify-content-between lh-condensed">
                                                        <div>
                                                            <h6 className="my-0 text-bold text-16 text-white-main">{item.title} ({item.cartItems})</h6>
                                                            <small className="text-muted text-white-main">{item.description}</small>
                                                        </div>
                                                        <span className="text-bold text-13 text-white-main">RWF {nf.format(item.price)}</span>
                                                    </li>
                                                ))}

                                                <li className="list-group-item card-2 d-flex justify-content-between">
                                                    <span className="text-white-main">Total (RWF)</span>
                                                    <strong className="text-bold text-white-main">{nf.format(calcPrice)}</strong>
                                                </li>
                                            </ul>

                                            <form className="card p-2 d-none">
                                                <div className="input-group">
                                                    <input type="text" className="form-control" placeholder="Promo code" />
                                                    <div className="input-group-append">
                                                        <button type="submit" className="btn btn-secondary">Redeem</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="col-md-8 order-md-1">
                                            <h4 className="mb-3">Billing address</h4>
                                            <form onSubmit={(e) => showModal(e)} className="needs-validation" novalidate>
                                                <div className="row">
                                                    <div className="col-md-6 mb-3">
                                                        <label className="text-white-main" for="firstName">First name</label>
                                                        <input type="text" disabled readOnly placeholder="Firstname" className="form-control" value={session.user.name} />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <label className="text-white-main" for="lastName">Last name</label>
                                                        <input type="text" disabled readOnly placeholder="Lastname" value={session.user.lname} className="form-control" />
                                                    </div>
                                                </div>

                                                <div className="mb-3">
                                                    <label className="text-white-main" for="email">Email Address </label>
                                                    <input type="email" className="form-control" value={session.user.email} disabled readOnly />
                                                </div>

                                                <div className="mb-3">
                                                    <label className="text-white-main" for="firstName">Phone number</label>
                                                    <input name="phone" value={phone} onChange={e => onChangeOrder(e)} type="phone" className="form-control" placeholder="Phone number" required />
                                                    <div className="invalid-feedback">
                                                        Valid phone number is required.
                                                    </div>
                                                </div>

                                                <div className="mb-3">
                                                    <label className="text-white-main" for="address">Address</label>
                                                    <input name="Address1" value={Address1} type="text" onChange={e => onChangeOrder(e)} className="form-control" placeholder="1234 Main St" required />
                                                </div>

                                                <div className="mb-3">
                                                    <label className="text-white-main" for="address2">Address 2 <span className="text-muted">(Optional)</span></label>
                                                    <input name="Address2" value={Address2} type="text" onChange={e => onChangeOrder(e)} className="form-control" id="address2" placeholder="Apartment or suite" />
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-5 mb-3">
                                                        <Form.Group controlId="formBasicSelect">
                                                            <Form.Label className="text-white-main">Country</Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                value={country}
                                                                onChange={e => onChangeSelectCountry(e)}
                                                                name="country"
                                                                required
                                                            >
                                                                <option value="" disabled selected>Select country</option>
                                                                <option value="Rwanda">Rwanda</option>
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <Form.Group controlId="formBasicSelect">
                                                            <Form.Label className="text-white-main">City</Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                value={city}
                                                                onChange={e => onChangeSelectCity(e)}
                                                                name="city"
                                                                required
                                                            >
                                                                <option value="" disabled selected>Select city</option>
                                                                <option value="Kigali">Kigali</option>
                                                            </Form.Control>
                                                        </Form.Group>
                                                        {/* <div className="invalid-feedback">
                                                            Please provide a valid state.
                                                        </div> */}
                                                    </div>
                                                    <div className="col-md-3 mb-3">
                                                        <label className="text-white" for="zip">Zip</label>
                                                        <input name="zip" value={zip} onChange={e => onChangeOrder(e)} type="text" className="form-control" id="zip" placeholder="" />
                                                        <div className="invalid-feedback">
                                                            Zip code required.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="custom-control d-none custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" id="same-address" />
                                                    <label className="custom-control-label" for="same-address">Shipping address is the same as my billing address</label>
                                                </div>
                                                <div className="custom-control d-none custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" id="save-info" />
                                                    <label className="custom-control-label" for="save-info">Save this information for next time</label>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 text-white-main">
                                                        Location Data
                                                        <hr />
                                                    </div>
                                                    <iframe height="250px" className="col-md-10 col-sm-10" title="map location checkout" src={`https://maps.google.com/maps?q=${geoData.lat},${geoData.long}&t=&z=15&ie=UTF8&iwloc=&output=embed`} />
                                                </div>
                                                <hr className="mb-4" />
                                                <Button htmlType='submit' className="btn-block" type="primary">
                                                    <span className='text-white-main'>Process Checkout</span>  
                                                </Button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <Modal title="Payment process" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                {loading ? (<span><Spinner
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                    size="sm"
                /> Processing Order </span>) : (
                        <Button onClick={() => makePayment()} htmlType='submit' className="btn-block text-white-main" type="primary">
                        Process Payment
                    </Button>)}
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        pathname: state.pathname.pathname,
        signed: state.signed.signed,
        cart_items: state.cart.cart_data,
        loading: state.add_an_order.loading,
        order_is_added: state.add_an_order.is_added,
        orders: state.add_an_order.orders,
    }
}
export default connect(mapStateToProps, { add_an_order })(CheckOut)