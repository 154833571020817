import React, { useCallback, useEffect, useState } from 'react'
import AdminNavbar from './shared/AdminNavbar'
import AdminSidebar from './shared/AdminSidebar'
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
} from 'react-bs-datatable';
import { Button, Col, Form, Row, Spinner, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { message, Modal, notification, Popconfirm, Skeleton, Tooltip } from 'antd';
import { FileUploader } from "react-drag-drop-files";
const fileTypes = ["JPG", "JPEG", "PNG", "GIF", "png", "jpg", "gif", "jpeg"];
function AdminProducts() {
    const token = sessionStorage.getItem("token")
    const [loading, setLoading] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false)
    const [loadingDelete, setLoadingDelete] = useState(false)
    const [prodLoad, setProdLoad] = useState(false);
    const [products, setProducts] = useState([])
    const [product, setProduct] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [formData, setFormData] = useState({
        Title: "",
        Description: "",
        PricebeforeDiscount:0,
        PriceAfterDiscount:0,
        Category:0
    })

    const [categories, setCategories] = useState([]);
    const [loadCtgs, SetLoadCategories] = useState(false);

    const { Title, Description, PricebeforeDiscount, PriceAfterDiscount, Category } = formData;

    useEffect(() => {
        document.getElementById('navbar-header').style.display = "none";
        document.getElementById('main-footer').style.display = "none";
        getProductsCallback()
        getCategoriesCallback()
    })


    const getCategories = async () => {
        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };

        SetLoadCategories(true)
        await axios.get(`${process.env.REACT_APP_API_URL}/getAllCategories`, config)
            .then(res => {
                if (res.data.message) {
                    SetLoadCategories(false)
                    notification["error"]({
                        message: 'Something went wrong',
                        description: `${res.data.message}`,
                        duration: 6,
                        placement: 'topRight'
                    });
                } else {
                    SetLoadCategories(false)
                    setCategories(res.data)
                }
            })
            .catch(err => {
                SetLoadCategories(false)
                notification["error"]({
                    message: 'Something went wrong!',
                    description: `${err.message}`,
                    duration: 6,
                    placement: 'topRight'
                });
            })
    }

    const getCategoriesCallback = useCallback(() => {
        if (categories.length === 0)
            getCategories()
    }, [categories.length])

    const getProducts = async () => {
        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };

        setLoading(true)
        await axios.get(`${process.env.REACT_APP_API_URL}/getAllProducts`, config)
            .then(res => {
                if (res.data.message) {
                    setLoading(false)
                    notification["error"]({
                        message: 'Something went wrong',
                        description: `${res.data.message}`,
                        duration: 6,
                        placement: 'topRight'
                    });
                } else {
                    setLoading(false)
                    setProducts(res.data)
                }
            })
            .catch(err => {
                setLoading(false)
                notification["error"]({
                    message: 'Something went wrong!',
                    description: `${err.message}`,
                    duration: 6,
                    placement: 'topRight'
                });
            })
    }

    const getProductsCallback = useCallback(() => {
        if (products.length === 0)
            getProducts()
    }, [products.length])

    /* product edit */
    /* update category actions */
    const showModal = (data) => {
        setIsModalVisible(true);
        const getProduct = async () => {
            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            };

            setProdLoad(true)
            await axios.get(`${process.env.REACT_APP_API_URL}/getProduct/${data.id}`, config)
                .then(res => {
                    if (res.data.message) {
                        setProdLoad(false)
                        notification["error"]({
                            message: 'Something went wrong',
                            description: `${res.data.message}`,
                            duration: 6,
                            placement: 'topRight'
                        });
                    } else {
                        setProdLoad(false)
                        setProduct(res.data)
                        setFormData({
                            ...formData,
                            Title: res.data[0].Title,
                            Description: res.data[0].Description,
                            PricebeforeDiscount: res.data[0].PricebeforeDiscount,
                            PriceAfterDiscount: res.data[0].PriceAfterDiscount,
                            category: res.data[0].category
                        })
                        console.log('Get product', product)
                    }
                })
                .catch(err => {
                    setProdLoad(false)
                    notification["error"]({
                        message: 'Something went wrong!',
                        description: `${err.message}`,
                        duration: 6,
                        placement: 'topRight'
                    });
                })
        }
        getProduct();

    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };


    const deleteProduct = (param) => {
        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        setLoadingDelete(true)
        axios.delete(`${process.env.REACT_APP_API_URL}/deleteProduct/${param.id}`, config)
            .then(res => {
                if (res.data) {
                    setLoadingDelete(false)
                    notification["error"]({
                        message: 'Something went wrong',
                        description: `${res.data.message}`,
                        duration: 6,
                        placement: 'topRight'
                    });
                } else {
                    setLoadingDelete(false)
                    getProducts()
                    notification["success"]({
                        message: 'Success!',
                        description: `Product has been deleted successfully.`,
                        duration: 7,
                        placement: 'topRight'
                    });
                }
            })
            .catch(err => {
                setLoadingDelete(false)
                notification["error"]({
                    message: 'Something went wrong!',
                    description: `${err.message}`,
                    duration: 6,
                    placement: 'topRight'
                });
            })
    }

    function cancel(e) {
        console.log(e);
        message.error('Delete has been cancelled.');
    }

    const confirmDelete = (data) => {
        deleteProduct(data)
    }

    const [files, setFile] = useState(null);
    const handleChange = (file) => {
        setFile(file);
    };

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const onChangeSelect = (e) => {
        setFormData({ ...formData, Category: e.target.value })
        console.log("Category", e.target.value)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        const reqBody = {
            Title: Title,
            Description: Description,
            PricebeforeDiscount: PricebeforeDiscount,
            PriceAfterDiscount: PriceAfterDiscount,
            Category: Category
        };

        setUpdateLoading(true)
        await axios.put(`${process.env.REACT_APP_API_URL}/updateProduct/${product[0].id}`, reqBody, config)
            .then(res => {
                if (res.data.message) {
                    setUpdateLoading(false)
                    notification["error"]({
                        message: 'Something went wrong',
                        description: `${res.data.message}`,
                        duration: 6,
                        placement: 'topRight'
                    });
                } else {
                    getProducts()
                    setUpdateLoading(false)
                    notification["success"]({
                        message: 'Success!',
                        description: `Product updated successfully.`,
                        duration: 7,
                        placement: 'topRight'
                    });
                    setIsModalVisible(false)
                }
            })
            .catch(err => {
                setUpdateLoading(false)
                notification["error"]({
                    message: 'Something went wrong!',
                    description: `${err.message}`,
                    duration: 6,
                    placement: 'topRight'
                });
            })
    }
    /* end of update actions  */
    /* end of that*/ 


    const headers = [
        { title: 'Title', prop: 'Title', isFilterable: true },
        { title: 'Description', prop: 'Description', isFilterable: true },
        { title: 'PricebeforeDiscount', prop: 'PricebeforeDiscount', isFilterable: true },
        { title: 'PriceAfterDiscount', prop: 'PriceAfterDiscount', isFilterable: true },
        { title: 'category', prop: 'category', isFilterable: true },
        { title: 'CreatedBy', prop: 'createdBy', isFilterable: false },
        {
            prop: "edit",
            title: "Edit",
            cell: (row) => {
                return (
                    <Button onClick={() => showModal(row)} className="rounded-50" size="xs" variant="success">
                        <Tooltip title="Update product" placement='bottom'>
                            <span><i className='bx bxs-edit-alt bx-sm text-white'></i></span>
                        </Tooltip>
                    </Button>
                );
            }
        },
        {
            prop: "delete",
            title: "Delete",
            cell: (row) => {
                return (
                    <Popconfirm
                        title="Are you sure to delete this product?"
                        onConfirm={() => confirmDelete(row)}
                        onCancel={cancel}
                        okText="Yes Delete!"
                        cancelText="Cancel"
                    >
                        {loadingDelete ? (
                            <Button className="rounded-50" size="xs" variant="danger">
                                <Tooltip title="Deletting" placement='bottom'>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        role="status"
                                        aria-hidden="true"
                                        size="sm"
                                    />
                                </Tooltip>
                            </Button>
                        ) : (
                            <Button className="rounded-50" size="xs" variant="danger">
                                <Tooltip title="Remove product" placement='bottom'>
                                    <span><i className='bx bxs-trash-alt bx-sm text-white'></i></span>
                                </Tooltip>
                            </Button>
                        )}

                    </Popconfirm>
                );
            }
        },
    ];

    const refreshButton = () => {
       setRefresh(true);
       getProducts()
    }

  return (
      <>
          <main className='body-main'>
              <AdminSidebar />
              <section id="content">
                  <AdminNavbar />
                  <main>
                      <div className="head-title">
                          <div className="left">
                              <h1>Cave Coffee</h1>
                              <ul className="breadcrumb">
                                  <li>
                                      <a href="#!">My Store</a>
                                  </li>
                                  <li><i className='bx bx-chevron-right' ></i></li>
                                  <li>
                                      <a className="active" href="#!">Products</a>
                                  </li>
                              </ul>
                          </div>
                          <a href="#!" className="btn-download d-none">
                              <i className='bx bxs-cloud-download' ></i>
                              <span className="text">Download PDF</span>
                          </a>
                      </div>

                      <div className="row">
                          <div className="col-md col-sm col-lg">
                              <div className="justify-content-end">
                                  <Link to="/admin/products/add-new" className="btn btn-primary float-right">
                                      <i className='bx bx-plus' ></i>
                                      <span className="text">Add Product</span>
                                  </Link>
                              </div>
                          </div>
                      </div>

                      <div className="table-data mb-6">
                          <div className="order">
                              <div className="row my-4">
                                  <div className="col-md col-sm col-lg">
                                      <div className="justify-content-end">
                                          <button onClick={refreshButton} className="btn btn-secondary float-right">
                                              {refresh && loading ? (<Spinner
                                                  as="span"
                                                  animation="border"
                                                  role="status"
                                                  aria-hidden="true"
                                                  size="sm"
                                              />) : (<span className="text">Refresh</span>)}
                                          </button>
                                      </div>
                                  </div>
                              </div>
                              <div className="head">
                                  <h3>Products</h3>
                                  <i className='bx bx-filter' ></i>
                              </div>
                              <DatatableWrapper body={products}
                                  paginationOptionsProps={{
                                      initialState: {
                                          rowsPerPage: 5,
                                          options: [5, 10, 15, 20]
                                      }
                                  }}
                                  headers={headers}>
                                  <Row className="mb-4">
                                      <Col
                                          xs={12}
                                          lg={4}
                                          className="d-flex flex-col justify-content-end align-items-end"
                                      >
                                          <Filter />
                                      </Col>
                                      <Col
                                          xs={12}
                                          sm={6}
                                          lg={4}
                                          className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
                                      >
                                          <PaginationOptions />
                                      </Col>
                                      <Col
                                          xs={12}
                                          sm={6}
                                          lg={4}
                                          className="d-flex flex-col justify-content-end align-items-end"
                                      >
                                          <Pagination />
                                      </Col>
                                  </Row>
                                  {loading ? (<Skeleton paragraph={{ rows: 8 }} active loading={loading} />) : (
                                      <Table>
                                          <TableHeader />
                                          <TableBody />
                                      </Table>
                                  )}
                              </DatatableWrapper>
                          </div>
                      </div>
                  </main>
              </section>
          </main>
          <Modal title="Update Product" visible={isModalVisible} footer={null} onOk={handleOk} onCancel={handleCancel}>
              {prodLoad ? (<Skeleton paragraph={{ rows: 8 }} avatar active loading={prodLoad} />) : (
                  <Form onSubmit={(e) => handleSubmit(e)} className='my-4'>
                      <Form.Group className="mb-3">
                          <Form.Label>Product name</Form.Label>
                          <Form.Control required name="Title" onChange={e => onChange(e)} value={Title} type="text" placeholder="Enter category title" />
                      </Form.Group>
                      <Form.Group className="mb-3">
                          <Form.Label>Description</Form.Label>
                          <Form.Control required name="Description" onChange={e => onChange(e)} value={Description} placeholder="Enter description" as="textarea" rows={4} />
                      </Form.Group>
                      <Form.Group className="mb-3">
                          <Form.Label>Price before discount</Form.Label>
                          <Form.Control required name="PricebeforeDiscount" onChange={e => onChange(e)} value={PricebeforeDiscount} type="number" placeholder="Enter price before discount" />
                      </Form.Group>
                      <Form.Group className="mb-3">
                          <Form.Label>Price after discount</Form.Label>
                          <Form.Control required name="PriceAfterDiscount" onChange={e => onChange(e)} value={PriceAfterDiscount} type="number" placeholder="Enter price after discount" />
                      </Form.Group>
                      <Form.Group controlId="formBasicSelect">
                          <Form.Label>Select category</Form.Label>
                          <Form.Control
                              as="select"
                              value={Category}
                              onChange={e => onChangeSelect(e)}
                          >
                              <option value="" disabled selected>Select product category</option>
                              {
                                  categories.map((item, index) => (
                                      <option value={item.id} key={index}>{item.Title}</option>
                                  ))
                              }
                          </Form.Control>
                      </Form.Group>
                      <Button className="mt-3" variant="primary" type="submit">
                          {updateLoading ? (<Spinner
                              as="span"
                              animation="border"
                              role="status"
                              aria-hidden="true"
                              size="sm"
                          />) : (<span>Submit </span>)}
                      </Button>
                  </Form>
                      
              )}
          </Modal>
    </>
  )
}

export default AdminProducts