import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import '../assets/cart.css';
import { get_cart_data } from '../redux/actions/get_cart_data';
import { remove_from_cart } from '../redux/actions/delete_cart';
import { empty_cart } from '../redux/actions/empty_cart';
import { decodedToken } from '../utils/decode';
// import { GET_CART_DATA_SUCCESS } from '../redux/actions/types'
const ShoppingCart = ({ cart_items, get_cart_data, remove_from_cart, empty_cart }) => {
    const navigate = useNavigate()
    const [calcPrice, setCalcPrice] = useState(0);
    var session = decodedToken(sessionStorage.getItem('encode-utf-8'))
    useMemo(() => {
        const calculatePrice = () => {
            if (cart_items) {
                if (cart_items.length) {
                    const priced = cart_items.map(item => item.price).reduce((prev, next) => prev + next);
                    setCalcPrice(priced)
                }
            }
        }
        calculatePrice()
    }, [cart_items]);

    const emptyCart = () => {
        empty_cart()
        reload()
    }

    const removeCartItem = (item) => {
        remove_from_cart(item.id)
        reload()
    }

    const reload = () => {
        const current = window.location.pathname
        navigate(`/reload`, { replace: true });
        setTimeout(() => {
            navigate(current, { replace: true });
        },100);
    }

    let nf = new Intl.NumberFormat('en-US');

    return (
        <>
            <div className="wrapper-main my-10">
                <h1 className='text-main text-white-main'>Shopping Cart</h1>
                {cart_items ? (
                    <div className="project">
                        <div className="shop">
                            {cart_items.length ? cart_items.map((item, key) => (
                                <div key={key} className="box-cont card-2 rounded-20">
                                    <img src={item ? item.images[0] ? (item.images[0].name) : '/images/cave/c1.jpg' : '/images/coffee-blog.png'} alt="" />
                                    <div className="content">
                                        <h3 className='text-white-main'>{item.title}</h3>
                                        <h4 className='text-white-main'>Price: RWF {nf.format(item.price)}</h4>
                                        <h5 className='text-white-main mt-n5 mb-5'> Price per product: RWF {nf.format(item.newPrice)} </h5>
                                        <p className="text-white-main unit">Quantity: {item.cartItems}</p>
                                        <p onClick={() => removeCartItem(item)} className="btn-area"><i aria-hidden="true" className="fa fa-trash"></i> <span className="btn2">Remove</span></p>
                                    </div>
                                </div>
                            )) : (
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 justify-content-center">
                                        <div className="card card-2 rounded-20">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-sm-12 col-md-12 my-9">
                                                            <h4 className="text-center text-white-main">Shopping cart is empty</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="right-bar card-2 rounded-20">
                            <p className='text-white-main' >
                                <span>Total</span>
                                <span className='text-small'>RWF {nf.format(calcPrice)}</span>
                            </p>
                            {calcPrice <= 0 ? (<a className="text-white disabled" disabled aria-disabled href="#!"><i className="fa fa-shopping-cart"></i>Checkout</a>) : (<Link to="/cart/check-out" className="text-white" href="#!"><i className="fa fa-shopping-cart"></i>Checkout</Link>) }
                            <button onClick={() => emptyCart()} className='btn btn-danger mt-3 btn-block' href="#!"><i className="fa fa-trash"></i>Empty cart</button>
                        </div>
                    </div>
                ) : (
                    <div className="row">
                        <div className="col-md-12 col-sm-12 justify-content-center">
                            <div className="card card-2 rounded-20">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-12 my-9">
                                                <h4 className="text-center text-white-main">Shopping cart is empty</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </>
    )


}


const mapStateToProps = state => {
    return {
        cart_items: state.cart.cart_data
    }
}

export default connect(mapStateToProps, { get_cart_data, remove_from_cart, empty_cart })(ShoppingCart)
