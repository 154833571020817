import { notification } from 'antd';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react'
import { Button, Form, Spinner } from 'react-bootstrap';
import AdminNavbar from '../shared/AdminNavbar';
import AdminSidebar from '../shared/AdminSidebar';
import { useNavigate } from 'react-router-dom';
import { FileUploader } from "react-drag-drop-files";

const fileTypes = ["JPG","JPEG","PNG", "GIF","png","jpg","gif","jpeg","mp4","webm","mkv"];
function AddHomeText() {
    const token = sessionStorage.getItem("token")
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        place: '',
        content:''
    })

    const [loading, setLoading] = useState(false)

    const { place, content} = formData;

    const [files, setFile] = useState(null);
    const handleChange = (file) => {
        setFile(file);
    };

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const onChangeSelect = (e) => {
        setFormData({ ...formData, place: e.target.value })
        console.log("Place", e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        const req = new FormData();
        req.append('Place', place)
        req.append('Content', content)
        if (files) {
            for (let i = 0; i < files.length; i++) {
                let file = files[i];
                req.append('images[' + i + ']', file);
            }
        }

        // console.log("Images", files);

        setLoading(true)
        axios.post(`${process.env.REACT_APP_API_URL}/addHomeText`, req, config)
            .then(res => {
                if (res.data.message) {
                    setLoading(false)
                    notification["error"]({
                        message: 'Something went wrong',
                        description: `${res.data.message}`,
                        duration: 6,
                        placement: 'topRight'
                    });
                } else {
                    setLoading(false)
                    notification["success"]({
                        message: 'Success!',
                        description: `Home text saved successfully.`,
                        duration: 7,
                        placement: 'topRight'
                    });
                    return navigate("/admin/home-texts");
                }
            })
            .catch(err => {
                setLoading(false)
                notification["error"]({
                    message: 'Something went wrong!',
                    description: `${err.message}`,
                    duration: 6,
                    placement: 'topRight'
                });
            })
    }




    useEffect(() => {
        document.getElementById('navbar-header').style.display = "none"
        document.getElementById('main-footer').style.display = "none";
        // document.getElementById('allbody').style.backgroundColor = '#eee';
    })

    return (
        <>
            <main className='body-main'>
                <AdminSidebar />
                <section id="content">
                    <AdminNavbar />
                    <main>
                        <div className="head-title">
                            <div className="left">
                                <h1>Cave Coffee</h1>
                                <ul className="breadcrumb">
                                    <li>
                                        <a href="#!">Home Texts</a>
                                    </li>
                                    <li><i className='bx bx-chevron-right' ></i></li>
                                    <li>
                                        <a className="active" href="#!">Add Home Texts</a>
                                    </li>
                                </ul>
                            </div>
                            <a href="#!" className="btn-download d-none">
                                <i className='bx bxs-cloud-download' ></i>
                                <span className="text">Download PDF</span>
                            </a>
                        </div>
                        
                        <div className="table-data mb-8">
                            <div className="order">
                                <div className="head">
                                    <h3>New Home Text</h3>
                                </div>
                                <div className="row">
                                    <div className="col-sm-7 col-md-7">
                                        <Form onSubmit={(e) => handleSubmit(e)} className='my-4'>
                                            <Form.Group controlId="formBasicSelect">
                                                <Form.Label>Select place </Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    value={place}
                                                    onChange={e => onChangeSelect(e)}
                                                >
                                                    <option defaultValue="" value="" selected>Select Place</option>
                                                    <option value="video1">Video Description 1</option>
                                                    <option value="video2">Video Description 2</option>
                                                    <option value="about">About us</option>
                                                    <option value="videoFile">Video File</option>
                                                    <option value="menu">Menu Image</option>
                                                    <option value="reservation">Reservation</option>
                                                </Form.Control>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Content</Form.Label>
                                                <Form.Control name="content" onChange={e => onChange(e)} value={content} placeholder="Enter content" as="textarea" rows={4} />
                                            </Form.Group>

                                            <Form.Group className="mb-3">
                                                <Form.Label>Images</Form.Label>
                                                <FileUploader multiple={ true} handleChange={handleChange} name="file" types={fileTypes} />
                                            </Form.Group>
                                            <Button variant="primary" type="submit">
                                                {loading ? (<Spinner
                                                    as="span"
                                                    animation="border"
                                                    role="status"
                                                    aria-hidden="true"
                                                    size="sm"
                                                />) : (<span>Submit </span>)}
                                            </Button>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </section>
            </main>
        </>
    )
}

export default AddHomeText