import { notification } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import AdminNavbar from '../shared/AdminNavbar';
import AdminSidebar from '../shared/AdminSidebar';
import { useLocation } from 'react-router-dom';
function AddCategory() {
    const token = sessionStorage.getItem("token")
    const navigate = useLocation();
    const [formData, setFormData] = useState({
        title: '',
        description:''
    })
    const [loading, setLoading]= useState(false)
    const { title, description } = formData;
    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name] : e.target.value })
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        const reqBody = {
            Title: title,
            Description: description,
        };

        setLoading(true)
        await axios.post(`${process.env.REACT_APP_API_URL}/AddCategory`, reqBody, config)
            .then(res => {
                if (res.data.message) {
                    setLoading(false)
                    notification["error"]({
                        message: 'Something went wrong',
                        description: `${res.data.message}`,
                        duration: 6,
                        placement: 'topRight'
                    });
                } else {
                    setLoading(false)
                    notification["success"]({
                        message: 'Success!',
                        description: `Category added successfully.`,
                        duration: 7,
                        placement: 'topRight'
                    });
                    return navigate("/admin/categories");
                }
            })
            .catch(err => {
                setLoading(false)
                notification["error"]({
                    message: 'Something went wrong!',
                    description: `${err.message}`,
                    duration: 6,
                    placement: 'topRight'
                });
            })
    }

    useEffect(() => {
        document.getElementById('navbar-header').style.display = "none"
        document.getElementById('main-footer').style.display = "none";
        // document.getElementById('allbody').style.backgroundColor = '#eee';
    })

    return (
        <>
            <main className='body-main'>
                <AdminSidebar />
                <section id="content">
                    <AdminNavbar />
                    <main>
                        <div className="head-title">
                            <div className="left">
                                <h1>Cave Coffee</h1>
                                <ul className="breadcrumb">
                                    <li>
                                        <a href="#!">Categories</a>
                                    </li>
                                    <li><i className='bx bx-chevron-right' ></i></li>
                                    <li>
                                        <a className="active" href="#!">Add Category</a>
                                    </li>
                                </ul>
                            </div>
                            <a href="#!" className="btn-download d-none">
                                <i className='bx bxs-cloud-download' ></i>
                                <span className="text">Download PDF</span>
                            </a>
                        </div>

                        <div className="row">
                            <div className="col-md col-sm col-lg">
                                <div className="justify-content-end">
                                    <a href="#!" className="btn btn-primary float-right">
                                        <i className='bx bx-plus' ></i>
                                        <span className="text">Add Category</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="table-data mb-8">
                            <div className="order">
                                <div className="head">
                                    <h3>Categories</h3>
                                    <i className='bx bx-search' ></i>
                                    <i className='bx bx-filter' ></i>
                                </div>
                                <div className="row">
                                    <div className="col-sm-7 col-md-7">
                                        <Form onSubmit={handleSubmit} className='my-4'>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Category</Form.Label>
                                                <Form.Control required name="title" onChange={e => onChange(e)} value={ title} type="text" placeholder="Enter category title" />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Description</Form.Label>
                                                <Form.Control required name="description" onChange={e => onChange(e)} value={ description} placeholder="Enter description" as="textarea" rows={4} />
                                            </Form.Group>
                                            <Button variant="primary" type="submit">
                                                {loading ? (<Spinner
                                                    as="span"
                                                    animation="border"
                                                    role="status"
                                                    aria-hidden="true"
                                                />) : (<span>Submit </span>)}
                                            </Button>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </section>
            </main>
        </>
    )
}

export default AddCategory