export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILED = 'REGISTER_FAILED';
export const SET_PATHNAME_SUCCESS = 'SET_PATHNAME_SUCCESS';
export const SET_PATHNAME_FAILED = 'SET_PATHNAME_FAILED'; 
export const SET_SIGNED_SUCCESSFUL = 'SET_SIGNED_SUCCESSFUL';
export const SET_SIGNED_FAILED = 'SET_SIGNED_FAILED';
export const ADD_TO_CART_SUCCESSFUL = 'ADD_TO_CART_SUCCESSFUL';
export const ADD_TO_CART_FAILED = 'ADD_TO_CART_FAILED';
export const GET_CART_DATA_SUCCESS = 'GET_CART_DATA_SUCCESS';
export const GET_CART_DATA_FAILED = 'GET_CART_DATA_FAILED';
export const DELETE_CART_ITEM_SUCCESS = 'DELETE_CART_ITEM_SUCCESS';
export const DELETE_CART_ITEM_FAILED = 'DELETE_CART_ITEM_FAILED';
export const EMPTY_CART_SUCCESS = 'EMPTY_CART_SUCCESS';
export const EMPTY_CART_FAILED = 'EMPTY_CART_FAILED';
export const ADD_ORDER_REQUEST = 'ADD_ORDER_REQUEST';
export const ADD_ORDER_SUCCESS = 'ADD_ORDER_SUCCESS';
export const ADD_ORDER_FAILED = 'ADD_ORDER_FAILED';