import { notification } from 'antd';
import axios from 'axios';
import React, {  useEffect, useState } from 'react'
import { Button, Form, Spinner } from 'react-bootstrap';
import AdminNavbar from '../shared/AdminNavbar';
import AdminSidebar from '../shared/AdminSidebar';
import { useLocation } from 'react-router-dom';
import { FileUploader } from "react-drag-drop-files";

const fileTypes = ["JPG", "JPEG", "PNG", "GIF", "png", "jpg", "gif", "jpeg"];
function AddBlog() {
    const token = sessionStorage.getItem("token")
    const navigate = useLocation();
    const [formData, setFormData] = useState({
        title: '',
        paragraph1: '',
        paragraph2: '',
    })

    const [loading, setLoading] = useState(false)

    const { title, paragraph1, paragraph2 } = formData;

    const [images, setImages] = useState(null);

    const handleChange = (file) => {
        setImages(file);
    };

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        const req = new FormData();
        req.append('Title', title)
        req.append('Paragraph1', paragraph1)
        req.append('Paragraph2', paragraph2)
        for (let i = 0; i < images.length; i++) {
            let file = images[i];
            req.append('images[' + i + ']', file);
        }

        // console.log("Images", files);

        setLoading(true)
        axios.post(`${process.env.REACT_APP_API_URL}/AddBlog`, req, config)
            .then(res => {
                if (res.data.message) {
                    setLoading(false)
                    notification["error"]({
                        message: 'Something went wrong',
                        description: `${res.data.message}`,
                        duration: 6,
                        placement: 'topRight'
                    });
                } else {
                    setLoading(false)
                    notification["success"]({
                        message: 'Success!',
                        description: `Blog saved successfully.`,
                        duration: 7,
                        placement: 'topRight'
                    });
                    return navigate("/admin/blogs");
                }
            })
            .catch(err => {
                setLoading(false)
                notification["error"]({
                    message: 'Something went wrong!',
                    description: `${err.message}`,
                    duration: 6,
                    placement: 'topRight'
                });
            })
    }



    useEffect(() => {
        document.getElementById('navbar-header').style.display = "none"
        document.getElementById('main-footer').style.display = "none";
        // document.getElementById('allbody').style.backgroundColor = '#eee';
    })

    return (
        <>
            <main className='body-main'>
                <AdminSidebar />
                <section id="content">
                    <AdminNavbar />
                    <main>
                        <div className="head-title">
                            <div className="left">
                                <h1>Cave Coffee</h1>
                                <ul className="breadcrumb">
                                    <li>
                                        <a href="#!">Blogs</a>
                                    </li>
                                    <li><i className='bx bx-chevron-right' ></i></li>
                                    <li>
                                        <a className="active" href="#!">Add Blog</a>
                                    </li>
                                </ul>
                            </div>
                            <a href="#!" className="btn-download d-none">
                                <i className='bx bxs-cloud-download' ></i>
                                <span className="text">Download PDF</span>
                            </a>
                        </div>

                        <div className="table-data mb-8">
                            <div className="order">
                                <div className="head">
                                    <h3>New Blog</h3>
                                </div>
                                <div className="row">
                                    <div className="col-sm-7 col-md-7">
                                        <Form onSubmit={(e) => handleSubmit(e)} className='my-4'>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Blog name</Form.Label>
                                                <Form.Control required name="title" onChange={e => onChange(e)} value={title} type="text" placeholder="Enter blog title" />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Paragraph 1</Form.Label>
                                                <Form.Control required name="paragraph1" onChange={e => onChange(e)} value={paragraph1} placeholder="Blog paragraph 1" as="textarea" rows={4} />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Paragraph 2</Form.Label>
                                                <Form.Control required name="paragraph2" onChange={e => onChange(e)} value={paragraph2} placeholder="Blog paragraph 2" as="textarea" rows={4} />
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Blog images</Form.Label>
                                                <FileUploader multiple={ true } handleChange={handleChange} name="file" types={fileTypes} />
                                            </Form.Group>
                                            <Button variant="primary" type="submit">
                                                {loading ? (<Spinner
                                                    as="span"
                                                    animation="border"
                                                    role="status"
                                                    aria-hidden="true"
                                                    size="sm"
                                                />) : (<span>Submit </span>)}
                                            </Button>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </section>
            </main>
        </>
    )
}

export default AddBlog