import { Carousel ,Button, notification, Skeleton } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { setpathname } from '../redux/actions/setpathname'
import { decodedToken } from '../utils/decode'
import SimpleImageSlider from "react-simple-image-slider";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel as OwlCarousel } from 'react-responsive-carousel';
// styles import 
import '../assets/hero/css/style.css';
import '../assets/hero/css/normalize.css';
import '../assets/slide-res.scss';
import { Link, useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
const token = sessionStorage.getItem("token");
const Reservation = ({ setpathname }) => {
    var session = decodedToken(sessionStorage.getItem('encode-utf-8'))
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([])
    const [hometext, setHomeText] = useState([])
    const [menu, setMenu] = useState([])
    var menuImages = [];
    const redirectToLogin = (logged) => {
        if (logged !== null) {
            if ((logged && logged.user.Type === "_user_site") || (logged.user.Type === "_user_google")) {
                return window.location.href = '/user-area';
            } else if (logged && logged.user.Type === "Admin") {
                return window.location.href = '/admin-dashboard';
            } else {
                return null;
            }
        }
    }
    useEffect(() => {
        const currentPath = window.location.href;
        setpathname(currentPath)
        redirectToLogin(session)
        const getProducts = async () => {
            const config = {
                headers: {
                    'Accept': 'application/json',
                }
            };
            setLoading(true)
            await axios.get(`${process.env.REACT_APP_API_URL}/getAllProducts`, config)
                .then(res => {
                    if (res.data.message) {
                        setLoading(false)
                        notification["error"]({
                            message: 'Something went wrong',
                            description: `${res.data.message}`,
                            duration: 6,
                            placement: 'topRight'
                        });
                    } else {
                        setLoading(false)
                        setProducts(res.data)
                        // console.log("Data products", res.data)
                    }
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        if (products.length === 0)
            getProducts()
        // get home texts
        const getTexts = async () => {
            const config = {
                headers: {
                    Accept: "application/json",
                },
                params: {
                    Place: "reservation",
                }

            };
            setLoading(true)
            await axios.get(`${process.env.REACT_APP_API_URL}/getHomeTextByPlace`, config)
                .then(res => {
                    if (res.data.message) {
                        setLoading(false)
                        notification["error"]({
                            message: 'Something went wrong',
                            description: `${res.data.message}`,
                            duration: 6,
                            placement: 'topRight'
                        });
                    } else {
                        setLoading(false)
                        setHomeText(res.data)
                        // console.log("Home texts reserva", hometext)
                    }
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        // get menu
        const getMenu = async () => {
            const config = {
                headers: {
                    Accept: "application/json",
                },
                params: {
                    Place: "menu",
                }

            };
            setLoading(true)
            await axios.get(`${process.env.REACT_APP_API_URL}/getHomeTextByPlace`, config)
                .then(res => {
                    if (res.data.message) {
                        setLoading(false)
                        notification["error"]({
                            message: 'Something went wrong',
                            description: `${res.data.message}`,
                            duration: 6,
                            placement: 'topRight'
                        });
                    } else {
                        setLoading(false)
                        setMenu(res.data)
                        
                        if (res.data[0]) {
                            res.data[0].images.forEach(item => {
                                // console.log("Item", item);
                                menuImages.push({ url: item.images })
                            })
                        }
                    }
                })
                .catch(err => {
                    setLoading(false)
                })
        }

        // if (hometext.length === 0)
        getTexts()
        getMenu();
        
            
        
    }, [setpathname, session, products.length])

    // handle add to cart
    const images = [
        
        { url: "/images/gallery/12.jpg" },
        { url: "/images/gallery/13.jpg" },
        { url: "/images/gallery/place.jpg" },
        {url:"/images/cave/about.jpeg"}
    ];

    // form handlers

    const [formData, setFormData] = useState({
        name:'',
        email: '',
        numberOfPeople: 0,
        notes: "",
        arrivalTime: "",
        phone: ""
    })
    const onChangeHandler = (e) => {
        setFormData({ ...formData, [e.target.name] : e.target.value })
    }

    const { name,email,numberOfPeople,notes,arrivalTime,phone } = formData;
    const [adding, setAdding] = useState(false)
    const handleSubmit = (e) => {
        e.preventDefault()
        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        
        const reaData = {
            "userName": name,
            "numberOfPeople": numberOfPeople,
            "notes": notes,
            "ArrivalDate": arrivalTime,
            "email": email,
            "phone": phone
        }
        
        setAdding(true)
        axios.post(`${process.env.REACT_APP_API_URL}/reservation`, reaData, config)
            .then(res => {
                if (res.data.message) {
                    setAdding(false)
                    notification["error"]({
                        message: 'Something went wrong',
                        description: `${res.data.message}`,
                        duration: 6,
                        placement: 'topRight'
                    });
                } else {
                    setAdding(false)
                    notification["success"]({
                        message: 'Success!',
                        description: `Reservation saved successfully.`,
                        duration: 7,
                        placement: 'topRight'
                    });
                }
            })
            .catch(err => {
                setAdding(false)
                notification["error"]({
                    message: 'Something went wrong!',
                    description: `${err.message}`,
                    duration: 6,
                    placement: 'topRight'
                });
            })
    }
    return (
        <>
            <section id="about" className='mt-6'>
                <div className="content w-100">
                    <div className="row">
                        <OwlCarousel className='h-75' autoPlay={true} infiniteLoop={true} interval={4000} dynamicHeight={true} stopOnHover>
                            <div>
                                <img src="/images/gallery/12.jpg" alt="" />
                                <p className="legend">Food</p>
                            </div>
                            <div>
                                <img src="/images/gallery/13.jpg" alt="" />
                                <p className="legend">Food</p>
                            </div>
                            <div>
                                <img src="/images/gallery/place.jpg" alt="" />
                                <p className="legend">Inner view</p>
                            </div>
                            <div>
                                <img src="/images/cave/about.jpeg" alt="" />
                                <p className="legend"> Front view </p>
                            </div>
                        </OwlCarousel>
                    </div>
                </div>
            </section>
                <section className="about rounded-20 mt-3" id="about">
                <h1 className="heading text-white-main"> <span>Bistro</span> Reservation</h1>
                    {loading ? (<Skeleton paragraph={{ rows: 16 }} active loading={loading} />) : (
                    <div className="row">
                        <div className="image">
                            {/* <img className='about-img' src='/images/cave/about.jpeg' alt="" /> */}
                            {hometext ? hometext[0] ? (<img className='about-img' src={hometext ? hometext[0].images.length ? hometext[0].images[0].images : '/images/about-img.jpeg' : '/images/about-img.jpeg'} alt="" />) : <img className='about-img' src='/images/about-img.jpeg' alt="" /> : <img className='about-img' src='/images/about-img.jpeg' alt="" />}
                        </div>
                        <div className="content">
                            <h3 className="text-17 text-white-main">About Bistro</h3>
                            <p className="text-white-main">{hometext ? hometext.length ? hometext[0].Content : null : null}</p>
                        </div>
                    </div>
                )}
            </section>
            <section className="menu" id="menu">
                <h1 className="heading"> our <span>menu</span> </h1>
                <div className="box-container">
                    {loading ? (<section className='my-3'>
                        <Skeleton paragraph={{ rows: 16 }} active loading={loading} />
                    </section>) :
                        (
                            <div className="row">
                                <div className="col-sm-12 col-md-12">
                                    <Carousel autoplay className="slider">
                                        {menu[0]
                                                ? menu[0].images.length ? menu[0].images.map((item,key) => (
                                                    <img key={ key} className='about-img w-100' src={ item.images } alt="" />
                                                )) :
                                            (<> <img className='about-img' src='/images/about-img.jpeg' alt="" /> </>)
                                        : (<> <img className='about-img' src='/images/about-img.jpeg' alt="" /> </>) }
                                    </Carousel>
                                </div>
                            </div>
                        )}
                </div>
            </section>
            <section className="contact" id="contact">
                <div className="row">
                    <div className="col-sm-6 col-md-6">
                        <form onSubmit={e => handleSubmit(e)} >
                            <h3 className="text-16 text-capitalize text-white-main">Make a Reservation</h3>
                            <div className="inputBox">
                                <span className="fas fa-user"></span>
                                <input name="name" onChange={(e) => onChangeHandler(e)} value={ name } type="text" placeholder="Name" />
                            </div>
                            <div className="inputBox">
                                <span className="fas fa-envelope"></span>
                                <input type="email" name="email" onChange={(e) => onChangeHandler(e)} value={ email }  placeholder="Email" />
                            </div>
                            <div className="inputBox">
                                <span className="fas fa-phone"></span>
                                <input type="phone" name='phone' onChange={(e) => onChangeHandler(e)} value={phone} placeholder="Phone number" />
                            </div>
                            <div className="inputBox">
                                <span className="fas fa-users"></span>
                                <input type="number" onChange={(e) => onChangeHandler(e)} name="numberOfPeople" value={ numberOfPeople } placeholder="Number of people" />
                            </div>
                            <div className="inputBox">
                                <span className="fas fa-clock"></span>
                                <input className='text-white-main' type="datetime-local" onChange={(e) => onChangeHandler(e)} value={arrivalTime} name="arrivalTime" placeholder="Arrival time" />
                            </div>
                            <div className="inputBox">
                                <textarea onChange={(e) => onChangeHandler(e)} value={ notes } name="notes" id="" placeholder='Notes' cols={100} rows="5"></textarea>
                            </div>
                            <Button type="primary" htmlType="submit">
                                {adding ? (<Spinner
                                    as="span"
                                    animation="border"
                                    role="status"
                                    aria-hidden="true"
                                    size="sm"
                                />) : (<span>Submit Now </span>)}
                            </Button>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default connect(null, { setpathname })(Reservation);
