import React from 'react'
import { Link } from 'react-router-dom';
const SideBar = ({ logout }) => {
    return (
        <section className="menu mt-6" id="menu">
            <div class="list-group list-custom-menu" style={{ 'borderRadius': 0 }}>
                <Link
                    to="/user-area/products"
                    style={{ 'background': '#010103', 'border-color': '#d3ad7f' }}
                    class="list-group-item list-group-item-action p-4 rounded-top-left-20 rounded-top-right-20"><i className="bx bx-layer"></i> Store</Link>
                <Link to="/orders" className="list-group-item list-group-item-action p-4"> <i className="bx bx-bookmarks"></i> Orders</Link>
                <Link to="/cart" className="list-group-item list-group-item-action p-4"> <i className="bx bx-cart"></i> Cart </Link>
                <Link to='/user-area/payments' className="list-group-item list-group-item-action p-4"> <i className="bx bx-dollar"></i> My Payments</Link>
                <Link to="/user-area/account" className="list-group-item list-group-item-action p-4"><i className="bx bx-user"></i> Account</Link>
                <a href="#!" onClick={logout} class="list-group-item list-group-item-action p-4"> <i className="bx bx-log-out"></i> Logout</a>
            </div>
        </section>
    )
}

export default (SideBar);
