import { notification, Skeleton } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { decodedToken } from '../../../utils/decode';
import SideBar from './Sidebar'

const Products = () => {
    var session = decodedToken(sessionStorage.getItem('encode-utf-8'))
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([])
    useEffect(() => {
        const getProducts = async () => {
            const config = {
                headers: {
                    'Accept': 'application/json',
                }
            };
            setLoading(true)
            await axios.get(`${process.env.REACT_APP_API_URL}/getAllProducts`, config)
                .then(res => {
                    if (res.data.message) {
                        setLoading(false)
                        notification["error"]({
                            message: 'Something went wrong',
                            description: `${res.data.message}`,
                            duration: 6,
                            placement: 'topRight'
                        });
                    } else {
                        setLoading(false)
                        setProducts(res.data)
                        // console.log("Data products", res.data)
                    }
                })
                .catch(err => {
                    setLoading(false)
                    // notification["error"]({
                    //     message: 'Something went wrong!',
                    //     description: `${err.message}`,
                    //     duration: 6,
                    //     placement: 'topRight'
                    // });
                })
        }
        if (products.length === 0)
            getProducts()
    }, [session, products.length])
    return (
        <>
            <div className="row">
                <div className="col-sm-2 col-md-2">
                    <SideBar />
                </div>
                <div className="col-sm-10 col-md-10">
                    <section className="products my-5 rounded-10" id="products">
                        <h1 className="heading"> our <span>products</span> </h1>
                        <div className="box-container">
                            
                            {
                                loading ? (
                                    <section className='my-5'>
                                        <Skeleton paragraph={{ rows: 15 }} active loading={loading} />
                                    </section>
                                ) : (
                                    products.map((prod, i) => (
                                        <div key={i} className="box">
                                            <div className="icons">
                                                <a href="#!" className="fas fa-shopping-cart d-none"></a>
                                                <Link to={`/product/details/${prod.id}`} className="fas fa-eye fa-2x my-5"></Link>
                                            </div>
                                            <div className="image row">
                                                <img className="col-sm-12 col-md-12 rounded-20" src={prod ? prod.images.length ? prod.images[0].name : '/images/coffee-blog.png' : '/images/coffee-blog.png'} alt="" />
                                            </div>
                                            <div className="content">
                                                <h3>{prod.Title}</h3>
                                                <div className="stars">
                                                    <i className="fas fa-star"></i>
                                                    <i className="fas fa-star"></i>
                                                    <i className="fas fa-star"></i>
                                                    <i className="fas fa-star"></i>
                                                    <i className="fas fa-star-half-alt"></i>
                                                </div>
                                                <div className="price">RWF {prod.PriceAfterDiscount} <span>RWF {prod.PricebeforeDiscount} </span></div>
                                            </div>
                                        </div>
                                    ))
                                )
                               
                            }

                        </div>

                    </section>
                </div>
            </div>
        </>
    )
}

export default Products