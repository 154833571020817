import { ADD_TO_CART_FAILED, ADD_TO_CART_SUCCESSFUL, GET_CART_DATA_FAILED, GET_CART_DATA_SUCCESS } from "../actions/types";

const initialState = {
    cart_data: null,
}

const addtocart = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case ADD_TO_CART_SUCCESSFUL:
        case GET_CART_DATA_SUCCESS:
            return {
                ...state,
                cart_data: payload
            }
        case ADD_TO_CART_FAILED:
        case GET_CART_DATA_FAILED:
            return state;
        default:
            return state;
    }
}

export default addtocart;