import { ADD_TO_CART_SUCCESSFUL, GET_CART_DATA_SUCCESS } from "./types";
export const add_to_cart = (data) => dispatch => {
    const cart = JSON.parse(localStorage.getItem('cart')) || []
    // add to it, only if it's empty
    var item = cart.find(item => item.id === data.id);

    if (item) {
        item.cartItems = data.cartItems;
        item.price = parseInt(data.newPrice) * parseInt(data.cartItems);
    } else {
        cart.push(data)
    }

    // then put it back.
    localStorage.setItem('cart', JSON.stringify(cart));
    console.log(cart);

    dispatch({
        type: ADD_TO_CART_SUCCESSFUL,
        payload: cart
    })
    dispatch({
        type: GET_CART_DATA_SUCCESS,
        payload: cart
    })

}


