import { SET_SIGNED_SUCCESSFUL, SET_SIGNED_FAILED } from "../actions/types";

const initialState = {
    signed: null
}

const setSigned = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_SIGNED_SUCCESSFUL:
            return {
                ...state,
                signed: payload,
            }

        case SET_SIGNED_FAILED:
            return state;
        default: return state;
    }
}
export default setSigned;