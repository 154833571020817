import { EMPTY_CART_SUCCESS } from "./types";
export const empty_cart = () => dispatch => {
    // const cart = []
    // localStorage.setItem("cart", JSON.stringify(cart))
    localStorage.removeItem("cart")
    dispatch({
        type: EMPTY_CART_SUCCESS,
        payload: [],
    })
}

