import { DELETE_CART_ITEM_SUCCESS, GET_CART_DATA_SUCCESS } from "./types";
export const remove_from_cart = (productId) => dispatch => {
    const cart = localStorage.getItem('cart');
    const temp = JSON.parse(cart)
    const products = temp.filter((item) => item.id !== productId)
    localStorage.setItem('cart', JSON.stringify(products));
    dispatch({
        type: DELETE_CART_ITEM_SUCCESS,
        payload: products
    })
    dispatch({
        type: GET_CART_DATA_SUCCESS,
        payload: products
    })
    // setTimeout(() => {
    //     window.location.reload()
    // }, 500)
}

