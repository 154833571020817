import { notification, Skeleton } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom';
import { setpathname } from '../redux/actions/setpathname'
import { decodedToken } from '../utils/decode'
const BlogDetails = ({ setpathname, match }) => {
    var session = decodedToken(sessionStorage.getItem('encode-utf-8'))
    const { id } = useParams();
    const [blog, setBlog] = useState([]);
    const [loadBlog, setLoadBlog] = useState([])
    const redirectToLogin = (logged) => {
        if (logged !== null) {
            if ((logged && logged.user.Type === "_user_site") || (logged.user.Type === "_user_google")) {
                return window.location.href = '/user-area';
            } else if (logged && logged.user.Type === "Admin") {
                return window.location.href = '/admin-dashboard';
            } else {
                return null;
            }
        }
    }
    useEffect(() => {
        const currentPath = window.location.href;
        setpathname(currentPath)
        redirectToLogin(session)
        // blogs
        const getBlog = async () => {
            const config = {
                headers: {
                    'Accept': 'application/json',
                }
            };
            setLoadBlog(true)
            await axios.get(`${process.env.REACT_APP_API_URL}/getBlog/${id}`, config)
                .then(res => {
                    if (res.data.message) {
                        setLoadBlog(false)
                        notification["error"]({
                            message: 'Something went wrong',
                            description: `${res.data.message}`,
                            duration: 6,
                            placement: 'topRight'
                        });
                    } else {
                        setLoadBlog(false)
                        setBlog(res.data)
                        // console.log("Blog", res.data)
                    }
                })
                .catch(err => {
                    setLoadBlog(false)
                })
        }
        if (blog.length === 0)
            getBlog()

    }, [setpathname, session, blog.length, id])
    return (
        <>
            {
                loadBlog ? (
                    <div className="row my-6">
                        <div className="container">
                            <Skeleton active paragraph={{ rows: 16 }} loading={loadBlog}></Skeleton>
                        </div>
                    </div>
                ) : (
                    <section className="blogs my-8" id="blogs">
                            <h1 className="heading text-white-main"> Blog<span> / {blog[0].Title }</span> </h1 >
                        <div className="box-container">
                            {
                                (
                                    <div className="box">
                                        <div className="image">
                                            <img src={blog[0].images.length > 0 ? blog[0].images[0].name : '/images/coffee-blog.png'} alt="" />
                                        </div>
                                        <div className="content">
                                            <a className="text-white-main title" href="#!">{blog[0].Title}</a>
                                            <p className="text-white-main">{blog[0].paragraph2}.</p>
                                        </div>
                                    </div>

                                )}
                        </div>
                    </section>
                )
            }
        </>
    )
}

export default connect(null, { setpathname })(BlogDetails);
