import React, { useEffect, useState } from 'react'
import { Button, message, Form, Input, Checkbox, Divider, notification } from 'antd';
import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';
import { MailOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { setpathname } from '../redux/actions/setpathname';
import { decodedToken } from '../utils/decode.js'
import axios from 'axios';
import { encoded } from '../utils/encode';
import { Spinner } from 'react-bootstrap';
import { settingSigned } from '../redux/actions/signed';
const Login = ({ pathname, setpathname, settingSigned, signed }) => {
    const [loginData, setLoginData] = useState({});
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    })
    const [loading, setLoading] = useState(false);
    const [gLoading, setGloading] = useState(false)
    const { email, password } = formData;
    const onChangeData = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    const handleFailure = (result) => {
        message.error(result, 5);
    }

    var session = decodedToken(sessionStorage.getItem('encode-utf-8'))

    const handleLogin = async (res) => {
        console.log("Google data", Array(res.profileObj));
        const _gdata = res.profileObj;
        const config = {
            headers: {
                'Accept': 'application/json',
                'X-XSRF-TOKEN': '',
            }
        };
        const reqBody = {
            email: _gdata.email,
            "By": "google"
        };

        setGloading(true)
        await axios.post(`${process.env.REACT_APP_API_URL}/login`, reqBody, config)
            .then(res => {
                if (res.data.message) {
                    setGloading(false)
                    notification["error"]({
                        message: 'Something went wrong',
                        description: `${res.data.message}`,
                        duration: 6,
                        placement: 'topRight'
                    });
                } else {
                    sessionStorage.setItem("token", res.data.token)
                    sessionStorage.setItem("encode-utf-8", encoded(res.data))
                    setGloading(false)
                    message.success(`Authentication successful`, 5)
                    setLoginData(decodedToken(sessionStorage.getItem('encode-utf-8')))
                    settingSigned(decodedToken(sessionStorage.getItem('encode-utf-8')))
                    setTimeout(() => {
                        redirectToLogin(session)
                    }, 1000)
                }
            })
            .catch(err => {
                setGloading(false)
                notification["error"]({
                    message: 'Something went wrong!',
                    description: `${err.message}`,
                    duration: 6,
                    placement: 'topRight'
                });
            })
    }

    const redirectToLogin = (logged) => {
        if (logged !== null) {
            if ((logged && logged.user.Type === "_user_site") || (logged.user.Type === "_user_google")) {
                return window.location.href = '/user-area';
            } else if (logged && logged.user.Type === "Admin") {
                return window.location.href = '/admin-dashboard';
            } else {
                return null;
            }
        }
    }

    useEffect(() => {
        function start() {
            gapi.client.init({
                clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                scope: ""
            })
        };
        gapi.load('client:auth2', start);
        const currentPath = window.location.href;
        setpathname(currentPath)
        redirectToLogin(session);
    }, [settingSigned, setpathname, session]);

    const handleLoginForm = async () => {
        axios.get('https://backend.cavecoffee.rw/sanctum/csrf-cookie').then(response => { })

        const config = {
            headers: {
                'Accept': 'application/json',
                'X-XSRF-TOKEN': '',
            }
        };
        const reqBody = {
            email: email,
            password: password,
            "By": "manually"
        };

        setLoading(true)
        await axios.post(`${process.env.REACT_APP_API_URL}/login`, reqBody, config)
            .then(res => {
                if (res.data.message) {
                    setLoading(false)
                    notification["error"]({
                        message: 'Something went wrong',
                        description: `${res.data.message}`,
                        duration: 6,
                        placement: 'topRight'
                    });
                } else {
                    sessionStorage.setItem("token", res.data.token)
                    sessionStorage.setItem("encode-utf-8", encoded(res.data))
                    setLoading(false)
                    message.success(`Authentication successful`, 5)
                    setLoginData(decodedToken(sessionStorage.getItem('encode-utf-8')))
                    settingSigned(decodedToken(sessionStorage.getItem('encode-utf-8')))
                    setTimeout(() => {
                        redirectToLogin(session)
                    }, 1000)
                }
            })
            .catch(err => {
                setLoading(false)
                notification["error"]({
                    message: 'Something went wrong!',
                    description: `${err.message}`,
                    duration: 6,
                    placement: 'topRight'
                });
            })
    }


    return (
        <>
            <div className="container-fluid mt-8 mb-6">
                <div className="row">
                    <div className="col-md col-sm"></div>
                    <div className="col-md-6 col-sm-6">
                        <div className='card card-2 rounded-10 p-3 pt-5'>
                            <div className="card-body">
                                <Form
                                    name="basic"
                                    labelCol={{ span: 5 }}
                                    wrapperCol={{ span: 16 }}
                                    initialValues={{ remember: true }}
                                    onFinish={handleLoginForm}
                                    autoComplete="off"
                                >
                                    <div className="row">
                                        <div className="col-sm-2 col-md-2"></div>
                                        <div className="col-sm col-md">
                                            <div className="ml-5">
                                                <Form.Item
                                                    name="email"
                                                    rules={[
                                                        {
                                                            type: 'email',
                                                            message: 'The input is not valid E-mail!',
                                                        },
                                                        {
                                                            required: true,
                                                            message: 'Please input your E-mail!',
                                                        },
                                                    ]}
                                                >
                                                    <Input name="email" placeholder="Email" onChange={e => onChangeData(e)} value={email} prefix={<MailOutlined />} />
                                                </Form.Item>

                                                <Form.Item
                                                    name="password"
                                                    rules={[{ required: true, message: 'Please input your password!' }]}
                                                >
                                                    <Input.Password name="password" placeholder="Password" onChange={e => onChangeData(e)} value={password} />
                                                </Form.Item>

                                            </div>
                                            <div className="ml-5">
                                                <Form.Item name="remember" valuePropName="checked">
                                                    <Checkbox> <span className="text-white-main"> Remember me </span></Checkbox>
                                                </Form.Item>

                                                <Form.Item>
                                                    <Button type="primary" block htmlType="submit">
                                                        {loading ? (<Spinner
                                                            as="span"
                                                            animation="border"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />) : (<span>Sign In </span>)}
                                                    </Button>
                                                </Form.Item>
                                            </div>
                                            <div className="text-center mr-3">
                                                <span className='text-white-main'>Don't have an account ? <Link className="text-white-main" to="/signup">signup</Link> </span>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                                <Divider> <i>OR</i> </Divider>
                                <div className="container">
                                    <div className="text-center">
                                        <GoogleLogin
                                            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                            onSuccess={handleLogin}
                                            onFailure={handleFailure}
                                            cookiePolicy={'single_host_origin'}
                                            className="text-black-main"
                                        >
                                            {gLoading ? (<Spinner
                                                as="span"
                                                animation="border"
                                                role="status"
                                                aria-hidden="true"
                                            />) : (<span>Login with Google </span>)}
                                        </GoogleLogin>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md col-sm"></div>
                </div>
            </div>
            {/* <GoogleLogout onLogoutSuccess={onLogoutSuccessful} clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          buttonText="Logout with Google" /> */}
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        pathname: state.pathname.pathname,
        signed: state.signed.signed
    }
}

export default connect(mapStateToProps, { setpathname, settingSigned })(Login)