import { SET_SIGNED_SUCCESSFUL, SET_SIGNED_FAILED } from "./types"

export const settingSigned = (data) => dispatch => {
    if (data) {
        dispatch({
            type: SET_SIGNED_SUCCESSFUL,
            payload: data
        })
    } else {
        dispatch({
            type: SET_SIGNED_FAILED
        })
    }
}