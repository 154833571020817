import { message, notification, Popconfirm, Skeleton, Tooltip } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Spinner, Button, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { decodedToken } from '../../../utils/decode';
import SideBar from './Sidebar'

const Payments = () => {
    var session = decodedToken(sessionStorage.getItem('encode-utf-8'))
    const token = sessionStorage.getItem("token")
    const [loading, setLoading] = useState(false);
    const [payments, setPayments] = useState([])
    useEffect(() => {
        const getPayments = async () => {
            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            };
            setLoading(true)
            await axios.get(`${process.env.REACT_APP_API_URL}/getUserPayments`, config)
                .then(res => {
                    if (res.data.message) {
                        setLoading(false)
                        notification["error"]({
                            message: 'Something went wrong',
                            description: `${res.data.message}`,
                            duration: 6,
                            placement: 'topRight'
                        });
                    } else {
                        setLoading(false)
                        setPayments(res.data)
                        // console.log("Data orders", res.data)
                    }
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        if (payments.length === 0)
            getPayments()
    }, [payments.length, token])

    return (
        <>
            <div className="row">
                <div className="col-sm-2 col-md-2">
                    <SideBar />
                </div>
                <div className="col-sm-10 col-md-10">
                    <section className="products" id="products">
                        <h1 className="heading"> My <span> payments </span> </h1>
                        {loading ? (
                            <Skeleton paragraph={{ rows: 15 }} active loading={loading} />
                        ) : (
                            <div className="table-responsive">
                                {payments.length ? (
                                    <table class="table table-striped card-2 rounded-20 text-13 text-white">
                                        <thead>
                                            <tr>
                                                <th scope="col"># Payment ID</th>
                                                <th scope="col">Order details (Country, City, Address,Zip and Location)</th>
                                                <th scope="col">Paid By </th>
                                                <th>Paid amount</th>
                                                <td colspan="2">Paid date</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                payments.map((item, index) => (
                                                    <tr key={index}>
                                                        <th scope="row">{index}</th>
                                                        <td>
                                                            {item.orderdetails.country}, {item.orderdetails.city}, {item.orderdetails.Address1} - {item.orderdetails.Address2} & {item.orderdetails.zip}
                                                        </td>
                                                        <td>{item.paidBy}</td>
                                                        <td>{item.Amount}</td>
                                                        <td>{item.paid_date}</td>
                                                    </tr>
                                                ))}

                                        </tbody>
                                    </table>
                                ) : (
                                    <Alert className="card-2" variant="secondary">
                                        <h5 className="text-white py-4">No payments found.</h5>
                                    </Alert>
                                )}
                            </div>
                        )}
                    </section>
                </div>
            </div>
        </>
    )
}

export default Payments