import React from 'react'
import { decodedToken } from '../../../utils/decode'
import SideBar from './Sidebar'
var session = decodedToken(sessionStorage.getItem('encode-utf-8'))
const UserAccount = () => {
    console.log("Session", session)
    return (
        <>
            <div className="row">
                <div className="col-sm-2 col-md-2">
                    <SideBar />
                </div>
                <div className="col-sm-10 col-md-10">
                    <div class="container my-8">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="">
                                    <div className="card-header p-5 bg-dark"></div>
                                    <div className="card-body text-center">
                                        <h5 className="card-title mt-4 text-white-main mb-0">{session.user.name} { session.user.lname}</h5>
                                        <span className='text-white-main my-3'>{ session.user.email }</span>
                                        <p className="card-text mt-2 d-none">Bio</p>
                                    </div>
                                    <div className="position-absolute profile-pic-wrapper">
                                        <img width="50px" height="50px" src="/images/admin/user-img.png" class="rounded-circle img-thumbnail" alt="Mads Obel" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserAccount