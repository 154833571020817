import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/style.css';
import './assets/bootstrap/dist/css/bootstrap.css';
import 'boxicons/css/boxicons.css';
import 'antd';
import '@ant-design/icons';
import './assets/styles/antd.css';
import './assets/admin/styles.css';
import 'jquery';
import App from './App';
import 'core-js';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <App />
);

