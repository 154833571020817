import { gapi } from 'gapi-script'
import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'
import { get_cart_data } from '../redux/actions/get_cart_data'
import Footer from './Footer'
import Navbar from './Navbar'
import { Outlet } from 'react-router-dom'

const Layout = ({ get_cart_data, children}) => {
  useEffect(() => {
    let navbar = document.querySelector('.navbar');

    document.querySelector('#menu-btn').onclick = () => {
      navbar.classList.toggle('active');
      searchForm.classList.remove('active');
      // cartItem.classList.remove('active');
    }

    let searchForm = document.querySelector('.search-form');

    document.querySelector('#search-btn').onclick = () => {
      searchForm.classList.toggle('active');
      navbar.classList.remove('active');
      // cartItem.classList.remove('active');
    }
    function start() {
      gapi.client.init({
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope: ""
      })
    };
    gapi.load('client:auth2', start);
    get_cart_data();
    // loading url
    // console.log("Live path", window.location.href);  
  })

  return (
    <Fragment>
        <Navbar/>
          <Outlet/>
        <Footer/>
    </Fragment>
  )
}

export default connect(null,{ get_cart_data})(Layout)