import { message } from 'antd';
import axios from 'axios';
import history from '../../utils/utils'
import { ADD_ORDER_REQUEST, ADD_ORDER_SUCCESS, ADD_ORDER_FAILED } from './types'
const token = sessionStorage.getItem("token")
export const add_an_order = (Address1, Address2, phone, country, city, zip, is_paid, Products, payment, location) => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    const body = {
        "Address1": Address1,
        "Address2": Address2,
        "phone": phone,
        "country": country,
        "city": city,
        "zip": zip,
        "is_paid": is_paid,
        "Products": Products,
        "payment": payment,
        "location": location
    } 
    let loading = false;
    try {
        loading = true;
        dispatch({
            type: ADD_ORDER_REQUEST,
            payload: loading
        })
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/MakeOrder`, body, config)
        if (res.data.message) {
            loading = false;
            dispatch({
                type: ADD_ORDER_REQUEST,
                payload: loading
            })
            dispatch({
                type: ADD_ORDER_FAILED
            })
            message.error(`${res.data.message}`,4)
        } else {
            loading = false;
            dispatch({
                type: ADD_ORDER_REQUEST,
                payload: loading
            })
            dispatch({
                type: ADD_ORDER_SUCCESS,
                payload: { order: res.data, is_added: true }
            })
            message.success(`Shopping order initialted succesfully.`, 5)
            setTimeout(() => {
                history.push('/orders')
            })
        }
    } catch (err) {
        loading = false;
        dispatch({
            type: ADD_ORDER_REQUEST,
            payload: loading
        })
        dispatch({
            type: ADD_ORDER_FAILED
        })
        message.error(err.message, 5)
    }
}