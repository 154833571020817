import {  message, notification, Popconfirm, Skeleton, Tooltip } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Spinner, Button, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { decodedToken } from '../../../utils/decode';
import SideBar from './Sidebar'

const Orders = () => {
    var session = decodedToken(sessionStorage.getItem('encode-utf-8'))
    const token = sessionStorage.getItem("token")
    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState([])
    useEffect(() => {
        const getOrders = async () => {
            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            };
            setLoading(true)
            await axios.get(`${process.env.REACT_APP_API_URL}/getPersonalOrder`, config)
                .then(res => {
                    if (res.data.message) {
                        setLoading(false)
                        notification["error"]({
                            message: 'Something went wrong',
                            description: `${res.data.message}`,
                            duration: 6,
                            placement: 'topRight'
                        });
                    } else {
                        setLoading(false)
                        setOrders(res.data)
                        // console.log("Data orders", res.data)
                    }
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        if (orders.length === 0)
            getOrders()
    }, [orders.length, token])

    // Get orders globaly
    const getOrdersGlobally = async () => {
        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        setLoading(true)
        await axios.get(`${process.env.REACT_APP_API_URL}/getPersonalOrder`, config)
            .then(res => {
                if (res.data.message) {
                    setLoading(false)
                    notification["error"]({
                        message: 'Something went wrong',
                        description: `${res.data.message}`,
                        duration: 6,
                        placement: 'topRight'
                    });
                } else {
                    setLoading(false)
                    setOrders(res.data)
                    // console.log("Data orders", res.data)
                }
            })
            .catch(err => {
                setLoading(false)
            })
    }
    // remove order
    const [deleting, setDeleting] = useState(false)
    const removeOrder = (param) => {
        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        setDeleting(true)
        axios.delete(`${process.env.REACT_APP_API_URL}/deleteOrder/${param.id}`, config)
            .then(res => {
                if (res.data) {
                    setDeleting(false)
                    notification["error"]({
                        message: 'Something went wrong',
                        description: `${res.data.message}`,
                        duration: 6,
                        placement: 'topRight'
                    });
                } else {
                    setDeleting(false)
                    getOrdersGlobally()
                    notification["success"]({
                        message: 'Success!',
                        description: `Order has been removed successfully.`,
                        duration: 7,
                        placement: 'topRight'
                    });
                }
            })
            .catch(err => {
                setDeleting(false)
                notification["error"]({
                    message: 'Something went wrong!',
                    description: `${err.message}`,
                    duration: 6,
                    placement: 'topRight'
                });
            })
    }


    function cancel(e) {
        console.log(e);
        message.error('Removing has been cancelled.');
    }


    return (
        <>
            <div className="row">
                <div className="col-sm-2 col-md-2">
                    <SideBar />
                </div>
                <div className="col-sm-10 col-md-10">
                    <section className="products" id="products">
                        <h1 className="heading"> My <span> orders </span> </h1>
                        {loading ? (
                            <Skeleton paragraph={{ rows: 15 }} active loading={loading} />
                        ) : (
                            <div className="table-responsive">
                                    {orders.length ? (
                                        <table class="table table-striped card-2 rounded-20 text-13 text-white-main">
                                            <thead>
                                                <tr>
                                                    <th scope="col"># Order ID</th>
                                                    <th scope="col">Country, City, Phone, Address & Zip</th>
                                                    <th scope="col">Is Paid </th>
                                                    <th scope="col">Ordered By</th>
                                                    <th>Products Details</th>
                                                    <td colspan="2">-------------</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    orders.map((item, index) => (
                                                        <tr key={index}>
                                                            <th scope="row">{index}</th>
                                                            <td>{item.country}, {item.city}, {item.phone} , {item.Address1} - {item.Address2} & {item.zip}</td>
                                                            <td>{item.is_paid === 'false' ? 'No' : 'Yes'}</td>
                                                            <td>{item.orderedBy}</td>
                                                            <td>
                                                                {item.productDetails.map((pd, k) => (
                                                                    <>
                                                                        <ul key={k}>
                                                                            <li>** Product Name: {pd.productName}</li>
                                                                            <li>** Quantity: {pd.productQuantity}</li>
                                                                            <li>** Sub total: {parseInt(pd.price_total) / parseInt(pd.productQuantity)}</li>
                                                                            <li>** Total Price: Rwf {pd.price_total}</li>
                                                                        </ul>
                                                                        <hr />
                                                                    </>
                                                                ))}
                                                            </td>
                                                            {/* <td>
                                                                <Link to="/orders/details/1"> <Button variant="success" type="success">View</Button> </Link>
                                                            </td> */}
                                                            {/* <td>
                                                                <Button  variant="outline-info" className="pl-2 pr-2 rounded-20"> Pay Now </Button>
                                                            </td> */}
                                                            <td>
                                                                {item.is_paid === 'false' ? ( <Popconfirm
                                                                    title="Are you sure to remove this order?"
                                                                    onConfirm={() => removeOrder(item)}
                                                                    onCancel={cancel}
                                                                    okText="Yes Delete!"
                                                                    cancelText="Cancel"
                                                                >
                                                                    {deleting ? (
                                                                        <Button className="rounded-50" size="xs" variant="outline-danger">
                                                                            <Tooltip title="Removing" placement='bottom'>
                                                                                <Spinner
                                                                                    as="span"
                                                                                    animation="border"
                                                                                    role="status"
                                                                                    aria-hidden="true"
                                                                                    size="sm"
                                                                                />
                                                                            </Tooltip>
                                                                        </Button>
                                                                    ) : (
                                                                        <Button className='rounded-50' variant="outline-danger">Remove</Button>

                                                                    )} 

                                                                </Popconfirm>
                                                                ) : null}
                                                            </td>
                                                        </tr>
                                                    ))}

                                            </tbody>
                                        </table>
                                    ) : ( 
                                    <Alert className="card-2" variant="secondary">
                                        <h5 className="text-white py-4">No orders found.</h5>
                                    </Alert>
                                )}
                            </div>
                        )}
                    </section>
                </div>
            </div>
        </>
    )
}

export default Orders