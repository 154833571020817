import { ADD_ORDER_REQUEST,ADD_ORDER_SUCCESS,ADD_ORDER_FAILED } from "../actions/types";

const initialState = {
    orders: [],
    loading: false,
    is_added: false,
}

const addorderReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case ADD_ORDER_REQUEST:
            return {
                ...state,
                loading: payload
            }
        case ADD_ORDER_SUCCESS:
            return {
                ...state,
                orders: payload.order,
                is_added: payload.is_added
            }
        case ADD_ORDER_FAILED:
            return state;
        default:
            return state;
    }
}

export default addorderReducer;