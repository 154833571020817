import { Button, Input, message, notification, Skeleton } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate ,useParams } from 'react-router-dom';
import { setpathname } from '../redux/actions/setpathname';
import { decodedToken } from '../utils/decode';
import '../assets/product.css';
import { add_to_cart } from '../redux/actions/add-to-cart';
import { get_cart_data } from '../redux/actions/get_cart_data';

const ProductDetails = ({ setpathname, cart_items, add_to_cart, get_cart_data }) => {
    var session = decodedToken(sessionStorage.getItem('encode-utf-8'))
    // console.log("Decoded", session);
    const navigate = useNavigate();
    const { id } = useParams();
    const [prod, setProd] = useState([]);
    const [loadProd, setLoadProd] = useState([])
    // const redirectToLogin = (logged) => {
    //     if (logged !== null) {
    //         if ((logged && logged.user.Type === "_user_site") || (logged.user.Type === "_user_google")) {
    //             return window.location.href = '/user-area';
    //         } else if (logged && logged.user.Type === "Admin") {
    //             return window.location.href = '/admin-dashboard';
    //         } else {
    //             return null;
    //         }
    //     }
    // }
    useEffect(() => {
        const currentPath = window.location.href;
        setpathname(currentPath)
        // redirectToLogin(session)
        // blogs
        const getProduct = async () => {
            const config = {
                headers: {
                    'Accept': 'application/json',
                }
            };
            setLoadProd(true)
            await axios.get(`${process.env.REACT_APP_API_URL}/getProduct/${id}`, config)
                .then(res => {
                    if (res.data.message) {
                        setLoadProd(false)
                        notification["error"]({
                            message: 'Something went wrong',
                            description: `${res.data.message}`,
                            duration: 6,
                            placement: 'topRight'
                        });
                    } else {
                        setLoadProd(false)
                        setProd(res.data)
                        // console.log("Product", res.data)
                    }
                })
                .catch(err => {
                    setLoadProd(false)
                })
        }
        if(prod.length === 0)
            getProduct()
        // 
    }, [setpathname, session, prod.length,id, get_cart_data])
    useEffect(() => {
        const imgs = document.querySelectorAll('.img-select a');
        const imgBtns = [...imgs];
        let imgId = 1;

        imgBtns.forEach((imgItem) => {
            imgItem.addEventListener('click', (event) => {
                event.preventDefault();
                imgId = imgItem.dataset.id;
                slideImage();
            });
        });

        function slideImage() {
            const displayWidth = document.querySelector('.img-showcase img:first-child').clientWidth;
            document.querySelector('.img-showcase').style.transform = `translateX(${- (imgId - 1) * displayWidth}px)`;
        }
        window.addEventListener('resize', slideImage);
    })



    const [cartItemNumber, setCartItemNumber] = useState(1)
  
    const reload = () => {
        const current = window.location.pathname
        navigate(`/reload`, { replace: true });
        setTimeout(() => {
            navigate(current, { replace: true });
        }, 500);
    }

    const handleCart = (data) => {
        setCartItemNumber(cartItemNumber + 1)
        sessionStorage.setItem("???", cartItemNumber)
        add_to_cart({
            cartItems: cartItemNumber,
            id: data.id,
            title: data.Title,
            price: parseInt(data.PriceAfterDiscount) * cartItemNumber,
            oldPrice: data.PricebeforeDiscount,
            newPrice: data.PriceAfterDiscount,
            description: data.Description,
            images: data.images
        })
        message.success("New item added to cart", 4);
        // setTimeout(() => {
        //     reload()
        // }, 500);
    }
    return (
        <>
            {
                loadProd ? (
                    <div className="row my-6">
                        <div className="container">
                            <Skeleton active paragraph={{ rows: 16 }} loading={loadProd}></Skeleton>
                        </div>
                    </div>
                ) : (
                    <div class="card-wrapper my-10">
                        <div class="card p-5  rounded-10 card-2 card-details">
                            <div class="product-imgs">
                                <div class="img-display">
                                    <div class="img-showcase">
                                        {prod ?
                                            prod[0].images[0] ? (
                                                prod[0].images.map((img, key) => (
                                                    <img key={key} class src={img.name} alt="" />
                                                ))
                                            ) : (<><img src={prod[0].images.length > 0 ? prod[0].images[0].name : '/images/coffee-blog.png'} alt="" /></>) : (
                                                <img src={prod[0].images.length > 0 ? prod[0].images[0].name : '/images/coffee-blog.png'} alt="" />
                                            )}
                                    </div>
                                </div>
                                <div class="img-select">
                                    {prod[0] ?
                                        prod[0].images.length ? (
                                            prod[0].images.map((img, key) => (
                                                <div key={key} class="img-item">
                                                    <a href="#!" data-id={key + 1}>
                                                        <img class src={img.name} alt="" />
                                                    </a>
                                                </div>
                                            ))
                                        ) : (<>
                                            <div class="img-item">
                                                <img src={prod[0].images.length > 0 ? prod[0].images[0].name : '/images/coffee-blog.png'} alt="" />
                                            </div>
                                            <div class="img-item">
                                                <img src={prod[0].images.length > 0 ? prod[0].images[0].name : '/images/coffee-blog.png'} alt="" />
                                            </div>
                                            <div class="img-item">
                                                <img src={prod[0].images.length > 0 ? prod[0].images[0].name : '/images/coffee-blog.png'} alt="" />
                                            </div>
                                        </>) : (
                                            <>
                                                <div class="img-item">
                                                    <img src={prod[0].images.length > 0 ? prod[0].images[0].name : '/images/coffee-blog.png'} alt="" />
                                                </div>
                                                <div class="img-item">
                                                    <img src={prod[0].images.length > 0 ? prod[0].images[0].name : '/images/coffee-blog.png'} alt="" />
                                                </div>
                                                <div class="img-item">
                                                    <img src={prod[0].images.length > 0 ? prod[0].images[0].name : '/images/coffee-blog.png'} alt="" />
                                                </div>
                                            </>
                                        )}
                                </div>
                            </div>
                            <div className="product-content">
                                    <h2 className="product-title text-white-main my-4">{prod[0].Title}</h2>
                                <div className="product-rating d-none">
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star-half-alt"></i>
                                    <span className='text-white'>4.7(21)</span>
                                </div>
                                <div className="product-price py-5">
                                        <p className="last-price text-white-main mb-4">Old Price: <span>RWF {prod[0].PricebeforeDiscount}</span></p>
                                        <p className="new-price text-white-main">New Price: <span>RWF {prod[0].PriceAfterDiscount}</span></p>
                                </div>
                                <div className="product-detail my-4">
                                        <h2 className='text-white-main'>About the product </h2>
                                        <p className='text-white-main'>{prod[0].Description}</p>
                                    <ul className='d-none'>
                                        <li>Color: <span>Black</span></li>
                                        <li>Available: <span>in stock</span></li>
                                        <li>Category: <span>Shoes</span></li>
                                        <li>Shipping Area: <span>All over the world</span></li>
                                        <li>Shipping Fee: <span>Free</span></li>
                                    </ul>
                                </div>

                                <div class="purchase-info">
                                    {/* <Input value={cartItemNumber} style={{ 'zIndex': '10000 !important','display':'none' }} min={1} defaultValue={1} name="pairs" type="number" /> */}
                                        <Button onClick={() => handleCart(prod[0])} className='rounded-50 text-white-main' style={{ 'zIndex': '10000 !important' }} type="primary" htmlType='button'>
                                        Add to Cart &nbsp; <i className="fas fa-shopping-cart"></i>
                                    </Button>
                                </div>

                                <div class="social-links d-none">
                                    <p>Share At: </p>
                                    <a href="#!">
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                    <a href="#!">
                                        <i className="fab fa-twitter"></i>
                                    </a>
                                    <a href="#!">
                                        <i className="fab fa-instagram"></i>
                                    </a>
                                    <a href="#!">
                                        <i className="fab fa-whatsapp"></i>
                                    </a>
                                    <a href="#!">
                                        <i className="fab fa-pinterest"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

const mapStateToProps = state => {
    return {
        cart_items: state.cart.cart_data
    }
}

export default connect(mapStateToProps, { setpathname, add_to_cart, get_cart_data })(ProductDetails);
