import React, { Component } from "react";
import "../../assets/gallery.css";
const imgs = [
  {
    author: "Cave Coffee",
    tag: "Coffee",
    src: "/images/gallery/1.jpg",
  },
  {
    author: "Cave Coffee",
    tag: "About",
    src: "/images/cave/about.jpeg",
  },
  {
    author: "Cave Coffee",
    tag: "About",
    src: "/images/gallery/place.jpg",
  },
  // {
  //   author: "Cave Coffee",
  //   tag: "Products",
  //   src: "/images/cart-item-1.png",
  // },
  // {
  //   author: "Cave Coffee",
  //   tag: "Products",
  //   src: "/images/gallery/6.jpeg",
  // },
  // {
  //   author: "Cave Coffee",
  //   tag: "Products",
  //   src: "/images/product-1.png",
  // },
  {
    author: "Cave Coffee",
    tag: "Products",
    src: "/images/gallery/PHOTO-2022-05-26-19-31-56.jpg",
  },
  {
    author: "Cave Coffee",
    tag: "Products",
    src: "/images/gallery/PHOTO-2022-05-26-19-31-55.jpg",
  },

  {
    author: "Cave Coffee",
    tag: "Coffee",
    src: "/images/gallery/1.jpg",
  },
  {
    author: "Cave Coffee",
    tag: "Others",
    src: "/images/gallery/5.jpg",
  },
  {
    author: "Cave Coffee",
    tag: "Others",
    src: "/images/about-img.jpeg",
  },
];

const filters = [
  { name: "Coffee", status: false },
  { name: "About", status: false },
  { name: "Products", status: false },
  { name: "Others", status: false },
];

const Filters = ({ onClickAll, all, onClick, filters }) => (
  <form className="gallery-form">
    <ul className="gallery-list">
      <li className="gallery-item" onClick={onClickAll}>
        <input className="gallery-check-box" type="checkbox" checked={all} />
        <label className="gallery-label" htmlFor="all">
          All
        </label>
      </li>
      {filters.map((filter, i) => (
        <li className="gallery-item" key={i} data-index={i} onClick={onClick}>
          <input
            className="gallery-check-box"
            id={filter.name}
            type="checkbox"
            checked={filter.status}
          />
          <label className="gallery-label" htmlFor={filter.name}>
            {filter.name}
          </label>
        </li>
      ))}
    </ul>
  </form>
);

const Cards = ({ imgs }) => (
  <ul>
    {imgs.map((img, i) => (
      <li className="gallery-item" key={i}>
        <figure className="gallery-figure">
          <img className="gallery-img" src={img.src} alt={img.author} />
          <figcaption className="gallery-figure-caption">
            <div className="author">{img.author} </div>
            <span className="tag">{img.tag}</span>
          </figcaption>
        </figure>
      </li>
    ))}
  </ul>
);

export class Gallery extends Component {
  state = {
    imgs,
    filters,
    all: true,
  };

  setFilter = (e) => {
    e.preventDefault();
    const { filters, all } = this.state;
    const { index } = e.currentTarget.dataset;

    filters[index].status = !filters[index].status;
    this.setState({
      filters,
    });

    this.updateFilters();
    this.updateImgs();
  };

  setAll = () => {
    const { filters } = this.state;

    filters.forEach((filter) => {
      filter.status = false;
    });

    this.setState({
      all: true,
      filters,
    });
  };

  updateFilters() {
    const allFiltersTrue = filters.every((filter) => filter.status === true);
    const allFiltersFalse = filters.every((filter) => filter.status === false);

    if (allFiltersTrue || allFiltersFalse) {
      this.setAll();
    } else {
      this.setState({
        all: false,
      });
    }
  }

  updateImgs() {
    const { filters, all } = this.state;
    let newImgs = [];
    let a = 0;

    imgs.forEach((img, imgKey) => {
      filters.forEach((filter, filterKey) => {
        if (img.tag === filter.name && filter.status === true) {
          newImgs[a] = img;
          a++;
        }
      });
    });

    this.setState({
      imgs: newImgs,
    });
  }
  render() {
    const { filters, all } = this.state;
    return (
      <div className="gallery-refine">
        <Filters
          onClickAll={this.setAll}
          all={all}
          onClick={this.setFilter}
          filters={filters}
        />
        {all ? <Cards imgs={imgs} /> : <Cards imgs={this.state.imgs} />}
      </div>
    );
  }
}

export default Gallery;
