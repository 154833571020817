import {
    GET_CART_DATA_SUCCESS,
    GET_CART_DATA_FAILED,
    DELETE_CART_ITEM_SUCCESS,
    DELETE_CART_ITEM_FAILED,
    EMPTY_CART_SUCCESS,
    EMPTY_CART_FAILED
} from "../actions/types";

const initialState = {
    cart_data: [],
}

const getcart = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_CART_DATA_SUCCESS:
            return {
                ...state,
                cart_data: payload
            }
        case DELETE_CART_ITEM_SUCCESS:
            return {
                ...state,
                cart_data: payload,
            }
        case EMPTY_CART_SUCCESS: 
            return {
                cart_data: payload
            }
        case EMPTY_CART_FAILED:
        case DELETE_CART_ITEM_FAILED:
        case GET_CART_DATA_FAILED:
            return state;
    
        default:
            return state;
    }
}

export default getcart;