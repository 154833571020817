import { SET_PATHNAME_FAILED, SET_PATHNAME_SUCCESS } from "./types"

export const setpathname = (url) => dispatch => {
    if (url) {
        dispatch({
            type: SET_PATHNAME_SUCCESS,
            payload: url
        })
    } else {
        dispatch({
            type: SET_PATHNAME_FAILED
        })
    }
}