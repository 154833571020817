import { notification } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { decodedToken } from '../../../utils/decode'
import SideBar from './Sidebar'
import { Skeleton } from 'antd'

const Userpage = () => {
    const [loading, setLoading] = useState(false)
    const [products, setProducts] = useState([])
    var session = decodedToken(sessionStorage.getItem('encode-utf-8'))
    useEffect(() => {
        const getProducts = async () => {
            const config = {
                headers: {
                    'Accept': 'application/json',
                }
            };
            setLoading(true)
            await axios.get(`${process.env.REACT_APP_API_URL}/getAllProducts`, config)
                .then(res => {
                    if (res.data.message) {
                        setLoading(false)
                        notification["error"]({
                            message: 'Something went wrong',
                            description: `${res.data.message}`,
                            duration: 6,
                            placement: 'topRight'
                        });
                    } else {
                        setLoading(false)
                        setProducts(res.data)
                        // console.log("Data products", res.data)
                    }
                })
                .catch(err => {
                    setLoading(false)
                })
        }
        if (products.length === 0)
            getProducts()
    },[products.length])
    return (
        <>
            <div className="row">
                <div className="col-sm-2 col-md-2">
                    <SideBar/>
                </div>
                <div className="col-sm-10 col-md-10">
                    <section className="menu" id="menu">
                        <h1 className="heading"><span>store</span> </h1>
                        <div className="box-container">
                            {loading ? (
                                <section className='my-5'>
                                    <Skeleton paragraph={{ rows: 15 }} active loading={loading} />
                                </section>
                            ) :
                             products.map((prod, i) => (
                                <div key={i} className="box">
                                    <div className="icons">
                                        <Link to={`/product/details/${prod.id}`} className="fas fa-eye fa-2x my-5"></Link>
                                    </div>
                                    <div className="content">
                                        <img className='rounded-circle' width="100px" height="200px" src={prod.images.length > 0 ? prod.images[0].name : '/images/coffee-blog.png'} alt="" />
                                        <h3>{prod.Title}</h3>
                                        <div className="price">RWF {prod.PriceAfterDiscount} <span>RWF {prod.PricebeforeDiscount} </span></div>
                                        <a href="#!" className="btn d-none">add to cart</a>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export default Userpage