import React, { useCallback, useEffect, useState } from 'react'
import AdminNavbar from './shared/AdminNavbar'
import AdminSidebar from './shared/AdminSidebar'
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
} from 'react-bs-datatable';
import { Button, Col, Form, Row, Spinner, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Modal, notification, Skeleton, Tooltip, Popconfirm, message } from 'antd';
import axios from 'axios';
function AdminCategories() {
    const token = sessionStorage.getItem("token")
    const [categories, setCategories] = useState([])
    const [loading, setLoading] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false)
    const [loadCtg,setLoadCtg] = useState(false)
    const [categorie, setCtg] = useState({})
    const [refresh, setRefresh] = useState(false)
    const [loadingDelete, setLoadingDelete] = useState(false)

    const getCategories = async () => {
        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };

        setLoading(true)
        await axios.get(`${process.env.REACT_APP_API_URL}/getAllCategories`, config)
            .then(res => {
                if (res.data.message) {
                    setLoading(false)
                    notification["error"]({
                        message: 'Something went wrong',
                        description: `${res.data.message}`,
                        duration: 6,
                        placement: 'topRight'
                    });
                } else {
                    setLoading(false)
                    setCategories(res.data)
                }
            })
            .catch(err => {
                setLoading(false)
                notification["error"]({
                    message: 'Something went wrong!',
                    description: `${err.message}`,
                    duration: 6,
                    placement: 'topRight'
                });
            })
    }

    const getCategoriesCallback = useCallback(() => {
        if (categories.length === 0)
            getCategories()
    }, [categories.length])

    useEffect(() => {
        document.getElementById('navbar-header').style.display = "none"
        document.getElementById('main-footer').style.display = "none";
        // document.getElementById('allbody').style.backgroundColor = '#eee';
        getCategoriesCallback()
    })

    /* update category actions */
    const showModal = (data) => {
        setIsModalVisible(true);
        const getCategory = async () => {
            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            };

            setLoadCtg(true)
            await axios.get(`${process.env.REACT_APP_API_URL}/getCategory/${data.id}`, config)
                .then(res => {
                    if (res.data.message) {
                        setLoadCtg(false)
                        notification["error"]({
                            message: 'Something went wrong',
                            description: `${res.data.message}`,
                            duration: 6,
                            placement: 'topRight'
                        });
                    } else {
                        setLoadCtg(false)
                        setCtg(res.data)
                        setFormData({ ...formData, title: res.data.Title, description: res.data.Description })
                        
                        // console.log('Get category', res.data)
                    }
                })
                .catch(err => {
                    setLoadCtg(false)
                    notification["error"]({
                        message: 'Something went wrong!',
                        description: `${err.message}`,
                        duration: 6,
                        placement: 'topRight'
                    });
                })
        }
        getCategory();

    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const [formData, setFormData] = useState({
        title: '',
        description: ''
    })
    const { title, description } = formData;

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const deleteCategory = (param) => {
        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        setLoadingDelete(true)
         axios.delete(`${process.env.REACT_APP_API_URL}/deleteCategory/${param.id}`, config)
            .then(res => {
                if (res.data) {
                    setLoadingDelete(false)
                    notification["error"]({
                        message: 'Something went wrong',
                        description: `${res.data.message}`,
                        duration: 6,
                        placement: 'topRight'
                    });
                } else {
                    setLoadingDelete(false)
                    getCategories()
                    notification["success"]({
                        message: 'Success!',
                        description: `Category has been deleted successfully.`,
                        duration: 7,
                        placement: 'topRight'
                    });
                }
            })
            .catch(err => {
                setLoadingDelete(false)
                notification["error"]({
                    message: 'Something went wrong!',
                    description: `${err.message}`,
                    duration: 6,
                    placement: 'topRight'
                });
            })
    }

    function cancel(e) {
        console.log(e);
        message.error('Delete has been cancelled.');
    }

    const confirmDelete = (data) => {
        deleteCategory(data)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        const reqBody = {
            Title: title,
            Description: description,
        };

        setUpdateLoading(true)
        await axios.put(`${process.env.REACT_APP_API_URL}/updateCategory/${categorie.id}`, reqBody, config)
            .then(res => {
                if (res.data.message) {
                    setUpdateLoading(false)
                    notification["error"]({
                        message: 'Something went wrong',
                        description: `${res.data.message}`,
                        duration: 6,
                        placement: 'topRight'
                    });
                } else {
                    setUpdateLoading(false)
                    notification["success"]({
                        message: 'Success!',
                        description: `Category updated successfully.`,
                        duration: 7,
                        placement: 'topRight'
                    });
                    setIsModalVisible(false)
                }
            })
            .catch(err => {
                setUpdateLoading(false)
                notification["error"]({
                    message: 'Something went wrong!',
                    description: `${err.message}`,
                    duration: 6,
                    placement: 'topRight'
                });
            })
    }
    /* end of update actions  */

    const headers = [
        { title: 'Title', prop: 'Title', isFilterable: true },
        { title: 'Description', prop: 'Description', isFilterable: true },
        {
            prop: "edit",
            title: "Edit",
            cell: (row) => {
                return (
                    <Button onClick={() => showModal(row)} className="rounded-50" size="xs" variant="success">
                        <Tooltip title="Update category" placement='bottom'>
                            <span><i className='bx bxs-edit-alt bx-sm text-white'></i></span>
                        </Tooltip>
                    </Button>
                );
            }
        },
        {
            prop: "delete",
            title: "Delete",
            cell: (row) => {
                return (
                    <Popconfirm
                        title="Are you sure to delete this category?"
                        onConfirm={() => confirmDelete(row)}
                        onCancel={cancel}
                        okText="Yes Delete!"
                        cancelText="Cancel"
                    >
                        {loadingDelete  ? (
                            <Button className="rounded-50" size="xs" variant="danger">
                                <Tooltip title="Deletting" placement='bottom'>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            role="status"
                                            aria-hidden="true"
                                            size="sm"
                                        />
                                </Tooltip>
                            </Button>
                        ) : (
                                <Button className="rounded-50" size="xs" variant="danger">
                                    <Tooltip title="Remove category" placement='bottom'>
                                        <span><i className='bx bxs-trash-alt bx-sm text-white'></i></span>
                                    </Tooltip>
                                </Button>
                        )}
                        
                    </Popconfirm>
                );
            }
        },
    ];

    // Randomize data of the table columns.
    // Note that the fields are all using the `prop` field of the headers.

    const refreshButton = () => {
        setRefresh(true);
        getCategories()
    }

    return (
        <>
            <main className='body-main'>
                <AdminSidebar />
                <section id="content">
                    <AdminNavbar />
                    <main>
                        <div className="head-title">
                            <div className="left">
                                <h1>Cave Coffee</h1>
                                <ul className="breadcrumb">
                                    <li>
                                        <a href="#!">Home</a>
                                    </li>
                                    <li><i className='bx bx-chevron-right' ></i></li>
                                    <li>
                                        <a className="active" href="#!">Categories</a>
                                    </li>
                                </ul>
                            </div>
                            <a href="#!" className="btn-download d-none">
                                <i className='bx bxs-cloud-download' ></i>
                                <span className="text">Download PDF</span>
                            </a>
                        </div>

                        <div className="row">
                            <div className="col-md col-sm col-lg">
                                <div className="justify-content-end">
                                    <Link to="/admin/category/add-new" className="btn btn-primary float-right">
                                        <i className='bx bx-plus' ></i>
                                        <span className="text">Add Category</span>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        

                        <div className="table-data mb-6">
                            <div className="order">
                                <div className="row my-4">
                                    <div className="col-md col-sm col-lg">
                                        <div className="justify-content-end">
                                            <button onClick={refreshButton} className="btn btn-secondary float-right">
                                                {refresh && loading ? (<Spinner
                                                as="span"
                                                animation="border"
                                                role="status"
                                                aria-hidden="true"
                                                size="sm"
                                                />) :(<span className="text">Refresh</span>) }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="head">
                                    <h3>Categories</h3>
                                    <i className='bx bx-filter' ></i>
                                </div>
                                <DatatableWrapper body={categories}
                                    paginationOptionsProps={{
                                        initialState: {
                                            rowsPerPage: 5,
                                            options: [5, 10, 15, 20]
                                        }
                                    }}
                                    headers={headers}>
                                    <Row className="mb-4">
                                        <Col
                                            xs={12}
                                            lg={4}
                                            className="d-flex flex-col justify-content-end align-items-end"
                                        >
                                            <Filter />
                                        </Col>
                                        <Col
                                            xs={12}
                                            sm={6}
                                            lg={4}
                                            className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
                                        >
                                            <PaginationOptions />
                                        </Col>
                                        <Col
                                            xs={12}
                                            sm={6}
                                            lg={4}
                                            className="d-flex flex-col justify-content-end align-items-end"
                                        >
                                            <Pagination />
                                        </Col>
                                    </Row>
                                    {loading ? (<Skeleton paragraph={{ rows: 8 }} active loading={loading} />) : (
                                        <Table>
                                            <TableHeader />
                                            <TableBody />
                                        </Table>
                                    )}
                                </DatatableWrapper>
                            </div>
                        </div>
                    </main>
                </section>
            </main>
            <Modal title="Update Category" visible={isModalVisible} footer={null} onOk={handleOk} onCancel={handleCancel}>
                {loadCtg ? (<Skeleton paragraph={{ rows: 8 }} avatar active loading={loadCtg} />) : (
                    <Form onSubmit={handleSubmit} className='my-4'>
                        <Form.Group className="mb-3">
                            <Form.Label>Category</Form.Label>
                            <Form.Control required name="title" onChange={e => onChange(e)} value={title} type="text" placeholder="Enter category title" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control required name="description" onChange={e => onChange(e)} value={description} placeholder="Enter description" as="textarea" rows={4} />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            {updateLoading ? (<Spinner
                                as="span"
                                animation="border"
                                role="status"
                                aria-hidden="true"
                            />) : (<span>Save changes </span>)}
                        </Button>
                    </Form>
                )}
            </Modal>
        </>
    )
}

export default AdminCategories