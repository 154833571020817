import React, { useState } from 'react';
import { Tooltip } from 'antd';
import Layout from '../src/components/Layout';
import AdminBlog from './pages/accounts/admin/pages/AdminBlog';
import AdminCategories from './pages/accounts/admin/pages/AdminCategories';
import AdminHome from './pages/accounts/admin/pages/AdminHome';
import AdminProducts from './pages/accounts/admin/pages/AdminProducts';
import AddBlog from './pages/accounts/admin/pages/create/AddBlog';
import AddCategory from './pages/accounts/admin/pages/create/AddCategory';
import AddProduct from './pages/accounts/admin/pages/create/AddProduct';
import Products from './pages/accounts/user/Products';
import Userpage from './pages/accounts/user/Userpage';
import BlogDetails from './pages/BlogDetails';
import Homepage from './pages/Homepage';
import Login from './pages/Login';
import Logout from './pages/Logout';
import ProductDetails from './pages/ProductDetails';
import ShoppingCart from './pages/ShoppingCart';
import SignUp from './pages/SignUp';
import {
  Route, BrowserRouter as Router, Routes, Navigate,
  Outlet,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import AdminHomeTexts from './pages/accounts/admin/pages/AdminHomeTexts';
import AddHomeText from './pages/accounts/admin/pages/create/AddHomeTexts';
import AboutDetails from './pages/AboutDetails';
import CheckOut from './pages/accounts/user/CheckOut';
import { decodedToken } from './utils/decode';
import Orders from './pages/accounts/user/Orders';
import OrderDetails from './pages/accounts/user/OrderDetails';
import Reservation from './pages/Reservation';
import Payments from './pages/accounts/user/Payments';
import UserAccount from './pages/accounts/user/UserAccount';
import AdminOrders from './pages/accounts/admin/pages/AdminOrders';
import AdminPayments from './pages/accounts/admin/pages/AdminPayments';
import AdminReservations from './pages/accounts/admin/pages/AdminReservations';
import useLocationStorage from 'use-local-storage';
var session = decodedToken(sessionStorage.getItem('encode-utf-8'))
const themeDefault = localStorage.getItem("dark");
const ProtectedRoute = ({ user, redirectPath = '/login' }) => {
  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }
  return <Outlet />;
};


// admin routes protector
const ProtectedAdminRoute = ({ user, type, redirectPath = '/login' }) => {
  if (!user && type === 'Admin') {
    return <Navigate to={redirectPath} replace />;
  }
  return <Outlet />;
};

function App() {

  const [theme, setTheme] = useLocationStorage('theme' ? 'dark' : 'light');
  const switchTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme)
  }




  return (
    <div className="app" data-theme={theme}>
      <body>
        <Provider store={store}>
          <Router>
            <Routes>
              <Route element={<Layout />}>
                <Route path="/" element={<Homepage />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/reload" element={null} key="reload" />
                <Route path='/cart' element={<ShoppingCart />} />
                <Route path='/about-us' element={<AboutDetails />} />
                <Route path='/blog/:id' element={<BlogDetails />} />
                <Route path='/product/details/:id' element={<ProductDetails />} />
                <Route path='/cave-coffee-reservation' element={<Reservation />} />
                {/* Admin routes */}
                <Route element={<ProtectedAdminRoute user={session} type={session ? session.user.type : null} />}>
                  <Route path='/admin-dashboard' element={<AdminHome />} />
                  <Route path='/admin/products' element={<AdminProducts />} />
                  <Route path='/admin/categories' element={<AdminCategories />} />
                  <Route path='/admin/category/add-new' element={<AddCategory />} />
                  <Route path='/admin/home-texts/add-new' element={<AddHomeText />} />
                  <Route path='/admin/products/add-new' element={<AddProduct />} />
                  <Route path='/admin/blogs' element={<AdminBlog />} />
                  <Route path='/admin/orders' element={<AdminOrders />} />
                  <Route path='/admin/payments' element={<AdminPayments />} />
                  <Route path='/admin/reservations' element={<AdminReservations />} />
                  <Route path='/admin/blogs/add-new' element={<AddBlog />} />
                  <Route path='/admin/home-texts' element={<AdminHomeTexts />} />
                </Route>
                {/* User routes */}
                <Route element={<ProtectedRoute user={session} />}>
                  <Route path='/cart/check-out' element={<CheckOut />} />
                  <Route path='/user-area/payments' element={<Payments />} />
                  <Route path='/user-area' element={<Userpage />} />
                  <Route path='/user-area/products' element={<Products />} />
                  <Route path='/orders' element={<Orders />} />
                  <Route path='/user-area/account' element={<UserAccount />} />
                  <Route path='/orders/details/:id' element={<OrderDetails />} />
                </Route>
              </Route>
            </Routes>
          </Router>
        </Provider>
        <div id="mybutton">
          <button onClick={switchTheme} class="theme-button btn btn-outline-secondary rounded-50 feedback p-2 mb-3 pr-3">
            {theme === 'light' ? (
              <Tooltip title="Switch to dark mode">
                <i className='bx bxs-sun bx-md text-white-main pl-1 pt-1' ></i>
              </Tooltip>
            ) : (
              <Tooltip title="Swith to light mode">
                <i className='bx bxs-moon bx-md text-white-main pl-1 pt-1' ></i>
              </Tooltip>
            )}
          </button>
        </div>
      </body>
    </div>


  );
}

export default App;
