import React, { useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import { connect } from 'react-redux'
import { settingSigned } from '../redux/actions/signed'

function Logout({ settingSigned }) {
  useEffect(() => {
    sessionStorage.removeItem("token")
    sessionStorage.removeItem("encode-utf-8")
    settingSigned(null)
    window.location.href = '/';
  })
  return (
    <>
      <div className="container mt-8 mb-6">
        <div className="row">
          <div className="card b-none h-100">
            <div className="card-body">
              <div className="d-flex justify-content-center">
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                  size="xl"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default connect(null, { settingSigned })(Logout)