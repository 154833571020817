import { notification, Skeleton } from 'antd';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import AdminNavbar from './shared/AdminNavbar';
import AdminSidebar from './shared/AdminSidebar';
const token = sessionStorage.getItem("token")
const AdminHome = () => {
    const [loading, setLoading] = useState(false)
    const [orders, setOrders] = useState([]);
    const getOrders = async () => {
        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };

        setLoading(true)
        await axios.get(`${process.env.REACT_APP_API_URL}/getAllOrders`, config)
            .then(res => {
                if (res.data.message) {
                    setLoading(false)
                    notification["error"]({
                        message: 'Something went wrong',
                        description: `${res.data.message}`,
                        duration: 6,
                        placement: 'topRight'
                    });
                } else {
                    setLoading(false)
                    setOrders(res.data)
                    console.log("Orders", res.data);
                }
            })
            .catch(err => {
                setLoading(false)
                notification["error"]({
                    message: 'Something went wrong!',
                    description: `${err.message}`,
                    duration: 6,
                    placement: 'topRight'
                });
            })
    }

    const getOrdersCallback = useCallback(() => {
        if (orders.length === 0)
            getOrders()
    }, [orders.length])

    useEffect(() => {
        getOrdersCallback()
    }, [getOrdersCallback])
    useEffect(() => {
        document.getElementById('navbar-header').style.display = "none"
        document.getElementById('main-footer').style.display = "none";
    })
    return (
        <main className='body-main'>
            <AdminSidebar/>
            <section id="content">
                <AdminNavbar/>
                <main>
                    <div className="head-title">
                        <div className="left">
                            <h1>Cave Coffee</h1>
                            <ul className="breadcrumb">
                                <li>
                                    <a href="#!">Dashboard</a>
                                </li>
                                <li><i className='bx bx-chevron-right' ></i></li>
                                <li>
                                    <a className="active" href="#!">Home</a>
                                </li>
                            </ul>
                        </div>
                        <a href="#!" className="btn-download d-none">
                            <i className='bx bxs-cloud-download' ></i>
                            <span className="text">Download PDF</span>
                        </a>
                    </div>

                    <ul className="box-info">
                        <li>
                            <i className='bx bxs-calendar-check' ></i>
                            <span className="text">
                                <h3>1020</h3>
                                <p>Products</p>
                            </span>
                        </li>
                        <li>
                            <i className='bx bxs-group' ></i>
                            <span className="text">
                                <h3>2834</h3>
                                <p>Visitors</p>
                            </span>
                        </li>
                        <li>
                            <i className='bx bxs-dollar-circle' ></i>
                            <span className="text">
                                <h3>$2543</h3>
                                <p>Total Sales</p>
                            </span>
                        </li>
                    </ul>


                    <div className="table-data mb-6">
                        <div className="order">
                            <div className="head">
                                <h3>Recent Orders</h3>
                                <i className='bx bx-search' ></i>
                                <i className='bx bx-filter' ></i>
                            </div>
                            {loading ? (<Skeleton paragraph={{ rows: 16 }} active loading={loading} />) : (
                                <table>
                                    <thead>
                                        <tr>
                                            <th>User</th>
                                            <th>Date Order</th>
                                            <th>Is Paid</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orders ? orders.map((item, index) => (
                                            <tr>
                                                <td>
                                                    <img alt='' src="/images/admin/user-img.png" />
                                                    <p>{item.orderedBy }</p>
                                                </td>
                                                <td> { item.Location.created_at } </td>
                                                <td>
                                                    {item.is_paid === "true" ? (
                                                        <span className="status completed">{item.is_paid === "true" ? 'Yes' : 'No'}</span>
                                                    ) : (
                                                        <span className="status pending">{item.is_paid === "true" ? 'Yes' : 'No'}</span>
                                                    )}
                                                </td>
                                            </tr>
                                        )) : <></>}
                                    </tbody>
                                </table>
                            )}
                        </div>
                        <div className="todo d-none">
                            <div className="head">
                                <h3>Todos</h3>
                                <i className='bx bx-plus' ></i>
                                <i className='bx bx-filter' ></i>
                            </div>
                            <ul className="todo-list">
                                <li className="completed">
                                    <p>Todo List</p>
                                    <i className='bx bx-dots-vertical-rounded' ></i>
                                </li>
                                <li className="completed">
                                    <p>Todo List</p>
                                    <i className='bx bx-dots-vertical-rounded' ></i>
                                </li>
                                <li className="not-completed">
                                    <p>Todo List</p>
                                    <i className='bx bx-dots-vertical-rounded' ></i>
                                </li>
                                <li className="completed">
                                    <p>Todo List</p>
                                    <i className='bx bx-dots-vertical-rounded' ></i>
                                </li>
                                <li className="not-completed">
                                    <p>Todo List</p>
                                    <i className='bx bx-dots-vertical-rounded' ></i>
                                </li>
                            </ul>
                        </div>
                    </div>
                </main>
            </section>
        </main>
    )
}

export default AdminHome