import React from 'react'
import { Link } from 'react-router-dom'

function AdminSidebar() {
    return (
        <>
            <section id="sidebar">
                <Link to="/admin-dashboard" className="brand">
                    <img src="/images/logo.png" className='pl-2 pt-2' alt="Cave Coffee" width="80px" height="80px" />
                </Link>
                <ul className="side-menu top">
                    <li className="active">
                        <Link to="/admin-dashboard">
                            <i class='bx bxs-dashboard'></i>
                            <span className="text">Dashbaord</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/admin/categories">
                            <i className='bx bx-category-alt'></i>
                            <span className="text">Categories</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/admin/products">
                            <i className='bx bxs-shopping-bag-alt' ></i>
                            <span className="text">My Store</span>
                        </Link>
                    </li>
                    
                    <li>
                        <Link to="/admin/orders">
                            <i className='bx bx-credit-card-alt'></i>
                            <span className="text">Orders</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/admin/payments">
                            <i className='bx bx-money' ></i>
                            <span className="text">Payments</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/admin/home-texts">
                            <i className='bx bxs-doughnut-chart' ></i>
                            <span className="text">Home texts</span>
                       </Link>
                    </li>
                    <li>
                        <Link to="/admin/reservations">
                            <i className='bx bx-book' ></i>
                            <span className="text">Reservations</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/admin/blogs">
                            <i className='bx bxl-blogger'></i>
                            <span className="text">Blog</span>
                        </Link>
                    </li>
                    <li>
                        <a href="#!">
                            <i className='bx bxs-message-dots' ></i>
                            <span className="text">Contact</span>
                        </a>
                    </li>
                </ul>
                <ul className="side-menu">
                    <li>
                        <a href="#!">
                            <i className='bx bxs-cog' ></i>
                            <span className="text">Settings</span>
                        </a>
                    </li>
                    <li>
                        <Link to="/logout" className="logout">
                            <i className='bx bxs-log-out-circle' ></i>
                            <span className="text">Logout</span>
                        </Link>
                    </li>
                </ul>
            </section>
        </>
    )
}

export default AdminSidebar