import { notification, Skeleton } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { decodedToken } from '../../../utils/decode';
import SideBar from './Sidebar'
import '../../../assets/order-details.scss';
const OrderDetails = () => {
    var session = decodedToken(sessionStorage.getItem('encode-utf-8'))
    const token = sessionStorage.getItem("token")
    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState([])
    useEffect(() => {
        const getOrders = async () => {
            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            };
            setLoading(true)
            await axios.get(`${process.env.REACT_APP_API_URL}/getPersonalOrder`, config)
                .then(res => {
                    if (res.data.message) {
                        setLoading(false)
                        notification["error"]({
                            message: 'Something went wrong',
                            description: `${res.data.message}`,
                            duration: 6,
                            placement: 'topRight'
                        });
                    } else {
                        setLoading(false)
                        setOrders(res.data)
                        console.log("Data orders", res.data)
                    }
                })
                .catch(err => {
                    setLoading(false)
                    // notification["error"]({
                    //     message: 'Something went wrong!',
                    //     description: `${err.message}`,
                    //     duration: 6,
                    //     placement: 'topRight'
                    // });
                })
        }
        if (orders.length === 0)
            getOrders()
    }, [orders.length, token])
    return (
        <>
            <div className="row">
                <div className="col-sm-2 col-md-2">
                    <SideBar />
                </div>
                <div className="col-sm-10 col-md-10">
                    <section className="products my-5 rounded-10  pl-0 pr-4" id="products">
                        <h1 className="heading">Order <span> details </span> </h1>
                    </section>
                    <section class="order-sum my-5 card-2 text-white">
                        <h1 class="order__title no-margin text-white">Order summary</h1>
                        <svg id="svg-summary" width="24" height="24" viewBox="0 0 24 24">
                            <path d="M7,8V6H5V19H19V6H17V8H7M9,4A3,3 0 0,1 12,1A3,3 0 0,1 15,4H19A2,2 0 0,1 21,6V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V6A2,2 0 0,1 5,4H9M12,3A1,1 0 0,0 11,4A1,1 0 0,0 12,5A1,1 0 0,0 13,4A1,1 0 0,0 12,3Z"></path>
                        </svg>
                        <section class="order__sub-sections order__subtotal clearfix-order ">
                            <h2 class="order__subtitles no-margin text-white">Subtotal</h2>
                            <table id="values" class="order__subtotal__table">
                                <tbody>
                                    <tr>
                                        <td class="first-row">Product (<a href="#!">1 item</a>)</td>
                                        <td class="first-row" align="right">R$ 179,00</td>
                                    </tr>
                                    <tr>
                                        <td>Freight</td>
                                        <td align="right">Free</td>
                                    </tr>
                                    <tr class="values--discounts">
                                        <td>Discount plus:</td>
                                        <td align="right">-R$ 10,00</td>
                                    </tr>
                                    <tr class="values--discounts">
                                        <td>Discount by type of payment:</td>
                                        <td align="right">-R$ 16,90</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td class="values__total">Total</td>
                                        <td class="values--total-price">R$ 152,10</td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" align="right">1x interest-free - R$ 152,10 (with discount)</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </section>
                        <section class="order__sub-sections order__address">
                            <h2 class="order__subtitles no-margin">Delivery address</h2>
                            <p class="address--client no-margin">Kyle Hide</p>
                            <p class="address--street no-margin">Cape West Street</p>
                            <p class="address--region no-margin">Red Crown - New York US</p>
                            <p class="address--zipcode no-margin">ZipCode: 341946</p>
                        </section>
                        <section class="order__sub-sections order__sms clearfix-order">
                            <div class="sms__svg">
                                <svg width="32" height="32" viewBox="0 0 24 24">
                                    <path d="M9,22A1,1 0 0,1 8,21V18H4A2,2 0 0,1 2,16V4C2,2.89 2.9,2 4,2H20A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H13.9L10.2,21.71C10,21.9 9.75,22 9.5,22V22H9Z"></path>
                                </svg>
                            </div>
                            <div class="sms__info">
                                <h2 class="order__subtitles no-margin">SMS Notification</h2>
                                <p class="no-margin">Celphone registered:</p>
                                <span>(00) 215-DUSK</span>
                                <form action="">
                                    <input type="number" class="info__celphone" />
                                    <button>Ok</button>
                                </form>
                            </div>
                            <button class="btn-primary">Remover</button>
                        </section>
                    </section>
                </div>
            </div>
        </>
    )
}

export default OrderDetails